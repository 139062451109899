.first-section {
  display: flex;
  height: 300px;
  margin-bottom: 3rem;
  margin-left: 6%;
  .section-column-list {
    width: 50%;
    h3 {
      font-size: 1.2rem;
      font-weight: bold;
      margin-top: 18px;
    }
    .columns {
      width: 95%;
      padding-right: 10px;
      height: 270px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 10px;
        height: 20px;
      }
      &::-webkit-scrollbar-thumb {
        background: linear-gradient(180deg, #ff642e, #ff642e);
        border-radius: 20px;
        box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25),
          inset -2px -2px 2px rgba(0, 0, 0, 0.25);
      }
      &::-webkit-scrollbar-track {
        background: linear-gradient(90deg, #ccc, #c2c2c2 1px, #ccc 0, #b1b1b1);
      }
      .box {
        display: flex;
        height: 30px;
        justify-content: space-between;
        align-items: center;
        p {
          margin: 0;
          font-size: 1rem;
          width: 80%;
          word-break: break-word;
          line-height: 1.1;
        }
      }
    }
  }
  .section-column-selected {
    width: 50%;
    display: flex;
    flex-direction: column;
    h3 {
      font-size: 1.2rem;
      font-weight: bold;
      margin-top: 18px;
    }
    .box {
      width: 100%;
      height: 110px;
      border: 1px solid;
      overflow-y: auto;
    }
    .btnSave {
      align-self: flex-end;
      width: 240px;
      height: 35px;
      border-radius: 12px;
      border: none;
      color: #fff;
      background-color: #ff642e;
      margin-top: 20px;
      font-size: 1.1rem;
    }
  }
}

.second-section {
  height: 300px;
  margin-left: 6%;
  .sc-fnykZs {
    display: none;
  }
  .Table {
    height: inherit;
  }
}

.boxIcons {
  background-color: #ff3d57;
  width: 30px;
  height: 40px;
  position: absolute;
  z-index: 301;
  left: -20px;
  cursor: pointer;
  transition: all 0.5s;
  i {
    line-height: 40px;
    margin-left: calc(65% - 11px);
    color: #fff;
  }
  &:hover {
    left: 0;
  }
}

#checkboxState {
  width: 18px;
  height: 18px;
}

@media (max-width: 1024px) {
  .container-etiquetas {
    height: 100%;
  }
  .first-section {
    flex-direction: column;
    height: auto;
    align-items: center;
    .section-column-list {
      width: 100%;
    }
    .section-column-selected {
      width: 100%;
      padding-right: 30px;
    }
  }
  .second-section {
    padding-right: 30px;
  }
}
