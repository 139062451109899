.noMargen {
  display: inline-block;
  font-size: unset !important;
  border-radius: 0 !important;
}
.file-status-bar {
  width: 100%;
  vertical-align: top;
  margin-top: 10px;
  margin-bottom: 20px;
  position: relative;
  line-height: 50px;
  height: 50px;
}
.file-status-bar>div {
  overflow: hidden;
}
.file-type-logo {
  width: 50px;
  height: 50px;
  background: url(../img/generic.png) no-repeat center center;
  background-size: 100%;
  position: absolute;
}
.file-type {
  display: inline-block !important;
  position: absolute;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  margin-top: 25px;
  padding: 0 4px;
  border-radius: 2px;
  box-shadow: 1px 1px 2px #abc;
  color: #fff;
  background: #0080c8;
  text-transform: uppercase;
}
.file-name {
  display: inline-block;
  vertical-align: top;
  margin-left: 55px;
  color: #4aa1f3;
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.file-size {
  display: inline-block;
  vertical-align: top;
  color: #30693D;
  margin-left: 10px;
  margin-right: 5px;
  margin-left: 10px;
  color: #444242;
  font-weight: 700;
  font-size: 14px;
}
.file-remove {
  position: absolute;
  top: 20px;
  right: 10px;
  line-height: 15px;
  cursor: pointer;
  color: red;
  margin-right: -10px;
}