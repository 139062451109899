.date_picker_camp_logout {
    .dataPick {
      .contDate {
        .react-google-flight-datepicker {
          .date-picker.custom-range-datepicker {
            .dialog-date-picker.open {
              inset: -250px 0 0 0 !important;
              width: 680px !important;
            }
          }
        }
      }
    }
  }
  
  @media screen and (max-width: 900px) {
    .title {
      margin-top: 1.5rem !important;
      font-size: 30px;
    }
    .contenedorCrearCampSinReg {
      overflow-y: hidden !important;
    }
  }
  
  @media screen and (max-width: 420px) {
    .title {
      margin-top: 1.7rem !important;
      font-size: 20px;
    }
  }
  
  @media screen and (max-width: 420px) {
    .title {
      margin-top: 1.8rem !important ;
      font-size: 14px;
    }
  }
  