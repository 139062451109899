a:hover {
  color: #fff;
  text-decoration: none;
}

a:hover[class^="_file_item_1aspn_80"] {
  color: dimgray !important;
}

a:hover[class^="_general_item_1aspn_117"] {
  color: dimgray !important;
}

.form-login {
  height: calc(100vh - 90px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.form-margin-login {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10%;
}

.lado {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.title-form {
  color: #292f4c;
  margin: 0;
  font-weight: bold;
  margin-bottom: 30px;
}

.subTitle-form {
  width: 100%;
  text-align: center;
  color: #292f4c;
  margin: 0;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 20px;
}

.textos {
  color: #292f4c;
  margin: 0;
  font-weight: bold;
  width: 80%;
  padding-left: 10px;
  font-size: 14px;
}

.passForget {
  width: 80%;
  text-align: right;
  color: #009aff;
  margin-bottom: 20px;
}

.passForget a:hover {
  color: #009aff;
}

.link {
  color: #009aff;
}

.link a:hover {
  color: #009aff;
}

.formInput {
  width: 80%;
  border-radius: 5px;
  border: none;
  box-shadow: 3px 3px 5px #80808080;
  margin: 3px 0 10px 0;
  padding: 10px;
}

.btn-plus-form {
  text-decoration: none;
  color: white;
  background: #ff642e;
  box-shadow: 1px 1px 5px #80808091;
  border-radius: 5px;
  font-weight: bold;
  font-size: 20px;
  width: 100%;
  text-align: center;
  margin: 10px 30% 0 30%;
  padding: 5px;
}
.formatTerms {
  margin-top: 10px !important;
}
.formatTerms:hover {
  color: #1877f2;
}
.formatTextRegister {
  padding-top: 10px;
}

.redes {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}
.redes span {
  height: 44px;
}
.redes span button {
  margin-bottom: 0 !important;
  height: 44px;
  border: none;
}
.redes span button i {
  margin-right: 10px;
}
.redes button {
  position: relative;
  font-family: -apple-system, Roboto !important;
  font-weight: bold !important;
}
.redes button span {
  font-weight: bold !important;
}

.redes a {
  flex-grow: 1;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 4px;
}

.facebook {
  background: #1877f2;
  color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 5px;
}

.google {
  padding: 10px;
  color: grey;
  font-weight: 500;
  border-radius: 3px;
  box-shadow: 0px 2px 3px #dbdbdb;
  margin-top: 10px;
}

.google img {
  width: 25px;
}

.google:hover {
  color: grey;
}

.people {
  width: 100%;
}
.form-login .invalid-feedback {
  padding-left: 0;
}

@media (max-width: 768px) {
  .form-margin-login {
    margin: 0;
    justify-content: center;
  }
  .form-margin-login .lado:nth-child(2) {
    display: none;
  }
  .form-margin-login .lado {
    width: 90%;
  }
}

.divInput {
  width: 80%;
  position: relative;
}
.divInputNumPhone {
  margin-top: 8px;
}

.formatImput2 {
  box-shadow: 3px 3px 5px #80808080;
  padding: 10px;
  float: right;
  width: 76%;
  height: 50px !important;
  border-radius: 5px;
  border: none;
}
.formatCode {
  box-shadow: 3px 3px 5px #80808080;
  width: 20%;
  float: left;
  border-radius: 5px;
  border: none;
}

.divInput .formInput {
  width: 100%;
}
.divInput .fa-eye::before {
  position: absolute;
  top: 20px;
  right: 50px;
}
.divInput .fa-eye-slash::before {
  position: absolute;
  top: 20px;
  right: 50px;
}
.divInputPass {
  margin-bottom: 0;
  height: 44px;
}
.textGenerar {
  display: block;
  width: 180px;
  cursor: pointer;
  color: #009aff;
  margin: 1.8rem 0 2rem;
  margin-right: calc(78% - 180px);
  position: relative;
  /* margin: 1.8rem 0 2rem;
  height: 30px;
  box-shadow: 0px 4px 5px #979797;
  border-radius: 5px;
  margin-right: calc(80% - 180px);
  text-align: center;
  line-height: 30px; */
}

.invalid-feedback {
  width: 78%;
  font-size: 13px;
}

@media screen and (max-width: 1160px) {
  .title-form {
    text-align: center;
  }
}

@media screen and (max-width: 840px) {
  .redes {
    gap: 1rem;
  }
}

@media screen and (max-height: 810px) {
  .form-login {
    height: 50%;
  }
}
