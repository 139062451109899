.footOneStepModal4 {
  padding: 0 6%;
  .firstBtn {
    width: 250px !important;
    text-align: center;
  }

  .secondBtn {
    width: 250px !important;
    text-align: center;
    margin-top: 0 !important;
  }
}

.inputDoble {
  margin-left: 2.5rem;
}

@media (max-width: 991px) {
  .footOneStepModal4 {
    flex-flow: row;
  }
}
@media (max-width: 780px) {
  .footOneStepModal4 {
    flex-flow: column;
  }
}

.footOneStepModal4 {
  padding: 0 6%;
  .firstBtn {
    width: 250px !important;
    text-align: center;
  }

  .secondBtn {
    width: 250px !important;
    text-align: center;
    margin-top: 0 !important;
  }
}

.content-budgets {
  .titleModal {
    background-color: #fff;
  }
  .content-modal {
    background-color: #fff;
    min-height: 600px !important;
    justify-content: flex-start;
    height: auto;
    max-height: 96vh;
    &__child {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
  }
  .header-modal {
    margin-top: 0;
  }
  .titleModal {
    padding-top: 40px;
  }
  .body-modal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 0;
    padding: 20px 40px 85px;
    align-items: flex-start;
    height: auto;
    overflow-x: hidden !important;
    background-color: #fff;

    .body-part {
      height: auto;
      .kaLCam {
        box-shadow: 3px 3px 5px #80808080;
      }
      .sc-llJcti {
        box-shadow: 3px 3px 5px #80808080;
      }
      .fTiFsA:nth-of-type(1) {
        min-width: 30px !important;
      }
      .gWwjkP:nth-of-type(1) {
        min-width: 30px;
      }
      .inputReserva {
        min-width: 40px;
        margin-right: 15px;
      }
      .btnSync {
        border: none;
        border-radius: 5px;
        background-color: #ff642e;
        color: #fff;
        width: 180px;
        text-align: center;
        height: 35px;
        margin-bottom: 20px;
      }
      .nameBudget {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        input {
          border: none;
          border: 1px solid #7e7e80;
          box-shadow: 3px 3px 5px #80808080;
          margin-left: 5px;
          height: 30px;
          padding: 5px;
        }
      }
    }
    .Buttons {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 30px;
      margin: auto 0;
      button {
        border: none !important;
        background-color: transparent;
        i {
          font-size: 35px !important;
          cursor: pointer;
        }
      }
    }
  }
  .footer-Modal {
    padding: 20px 5%;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    width: 100%;
    .btn {
      width: 240px;
      height: 40px;
      border-radius: 5px;
      font-size: 1rem;
      padding: 0;
      color: #fff;
      font-weight: bold;
      &_cancel {
        background-color: #7e7e80;
      }
      &_save {
        background-color: #ff642e;
      }
    }
  }
}
.body-modal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 0;
  padding: 20px 40px;
  align-items: flex-start;
  height: auto;
  overflow-x: hidden !important;

  .body-part {
    height: auto;
    .fTiFsA:nth-of-type(1) {
      min-width: 30px !important;
    }
    .gWwjkP:nth-of-type(1) {
      min-width: 30px;
    }
    .inputReserva {
      min-width: 30px;
      margin-right: 20px;
    }
    .btnSync {
      border: none;
      border-radius: 5px;
      background-color: #ff642e;
      color: #fff;
      width: 180px;
      text-align: center;
      height: 35px;
      margin-bottom: 20px;
    }
    .nameBudget {
      display: flex;
      width: 100%;
      input {
        width: 50%;
        border: none;
        box-shadow: 3px 3px 5px #80808080;
        margin-left: 5px;
        height: 30px;
        padding: 5px;
      }
    }
  }
  .Buttons {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    margin: auto 0;
    button {
      border: none !important;
      background-color: transparent;
      i {
        font-size: 35px !important;
        cursor: pointer;
      }
    }
  }
}
.btnSync {
  border: none;
  border-radius: 5px;
  background-color: #ff642e;
  color: #fff;
  width: 180px;
  text-align: center;
  height: 35px;
  margin-bottom: 20px;
}
.content-modal__presupuestos {
  width: 95%;
  background-color: #fff;
  min-height: 600px !important;
  justify-content: flex-start;
  height: auto;
  max-height: 96vh;
  .header-modal {
    margin: 30px 40px 0;
    .titleModal {
      background-color: #fff;
    }
  }

  .body-modal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 40px 85px;
    align-items: flex-start;
    height: auto;
    min-height: 20vh;
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden !important;
    background-color: #fff;
    .body-part {
      .nameBudget {
        align-items: center;
      }
    }
  }
  .footOneStep {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    background-color: #fff;
  }
}
@media (max-width: 1450px) {
  .content-budgets {
    .body-modal {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .body-part {
        width: 100%;
      }
      .Buttons {
        flex-direction: row;
        margin: 10px auto;
        button:nth-child(1) {
          transform: rotate(90deg);
        }
        button:nth-child(2) {
          transform: rotate(90deg);
        }
      }
    }
  }
  .body-modal {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .body-part {
      width: 100%;
    }
    .Buttons {
      flex-direction: row;
      margin: 10px auto;
      button:nth-child(1) {
        transform: rotate(90deg);
      }
      button:nth-child(2) {
        transform: rotate(90deg);
      }
    }
  }
}

@media (max-width: 991px) {
  .footOneStepModal4 {
    flex-flow: row;
  }
}
@media (max-width: 780px) {
  .footOneStepModal4 {
    flex-flow: column;
  }
}

@media (max-width: 500px) {
  .content-budgets .content-modal {
    height: 95vh;
    width: 90%;
  }
  .content-budgets
    .body-modal
    .body-part:nth-child(1)
    .contanerTable1
    .column
    button {
    font-size: 10px;
  }
  .content-budgets .body-modal .body-part:nth-child(1) .contanerTable1 .column {
    gap: 5px;
  }
  .content-budgets .body-modal {
    padding: 10px;
    height: 76%;
  }
  .content-budgets .footer-Modal {
    flex-direction: column;
    padding: 0;
    gap: 20px;
    align-items: center;
  }
}
.content-modal__presupuestos::-webkit-scrollbar {
  width: 6px; 

}

.content-modal__presupuestos::-webkit-scrollbar-thumb {
  background-color: #ff642e; 
  border-radius: 4px;
}
