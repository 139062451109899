.footOneStepModal3 {
    padding: 0 6%;
    .firstBtn {
      width: 250px !important;
      text-align: center;
    }
  
    .secondBtn {
      width: 250px !important;
      text-align: center;
      margin-top: 0 !important;
    }
  }
  
  .bodyOneStepPublico {
    height: 500px;
    overflow-y: auto;
  }
  
  @media (max-width: 991px) {
    .footOneStepModal3 {
      flex-flow: row;
    }
  }
  @media (max-width: 780px) {
    .footOneStepModal3 {
      flex-flow: column;
    }
  }
  
  .tagsCont1 {
    height: auto;
  }
  
  .bodyOneStepPublico {
    height: 500px;
    overflow-y: auto;
  }

  .upsert-modal {
    width: 85%;
    height: 60%;
    background-color: white; 
    margin: 0px;
    padding: 0px;
  }

  .select__vista__ {
    width: 20%;
    text-align: center;
    margin-right: 7px
  }
  .formOneStep {
    margin: 1rem 1rem;
    padding: 1rem 1rem;
  }
  