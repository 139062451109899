.content-all{
  min-height: 88vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.shadow{
  background: #00000070;
  position: absolute;
  width: 100%;
  height: calc(100vh - 60px);
  z-index: 1;
}

.form-dashboard{
  margin-top: 3vh;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.Bar-content{
  width: 100%;
}

.content-menu{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select-dashboard{
  width: 15%;
  border: none;
  border-radius: 5px;
  box-shadow: 3px 3px 5px #80808080;
  padding: 10px;
  color: #292f4c;
  font-weight: bold;
}

.width{
  width: 55%;
}

.content-floatWindow{
  position: relative;
}

.compartir-content{
  position: absolute;
  z-index: 2;
  background: white;
  border-radius: 5px;
  right: 80px;
  top: 50px;
  min-width: 360px;
}

.showColls-content{
  right: 45px;
}

.showFill-content{
  right: 0;
}

.cc-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  border-radius: 5px 5px 0 0;
  padding: 10px;
}

.cople{
  display: flex;
  align-items: center;
}

.ccHeader-ico{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  background: white;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 3px 3px 5px #80808080;
}

.ccHeader-text{
  margin-left: 10px;
}

.ccHeader-text p, .ccFoot-item p, .User-share p{
  margin: 0;
}

.ccHeader-titulo{
  font-weight: bold;
}

.sf-filter p{
  padding-left: 10px;
  margin: 0;
}

.sf-filter select{
  width: calc(100% - 20px);
  margin: 0 10px;
  padding: 10px;
  border: none;
  background: whitesmoke;
  border-radius: 5px;
  font-weight: 500;
  margin-bottom: 5px;
}

.sf-text{
  margin: 0 10px;
  width: calc(100% - 22px);
  border: 1px solid gray;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 5px;
}

.contentAllFilters{
  max-height: 55vh;
  overflow-y: scroll;
  margin: 5px 20px 5px 0;
}

.contentAllFilters::-webkit-scrollbar {
  width: 10px;
}

.contentAllFilters::-webkit-scrollbar-track {
  border-radius: 25px;
  background: linear-gradient(to right, white, #f5f5f5, #f5f5f5, #f5f5f5, white);
  width: 1px;
}

.contentAllFilters::-webkit-scrollbar-thumb {
  border-radius: 25px;
  background: #ff642e;
}



.User-share{
  width: 90px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  padding-top: 15px;
}

.us-user{
  width: 50px;
  border-radius: 25px;
}

.us-apli{
  width: 50px;
  border-radius: 5px;
}

.noPad{
  padding-top: 0;
}

.us-app{
  position: absolute;
  width: 25px;
  right: 15px;
}

.ccFoot-item{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: whitesmoke;
  margin: 5px 10px;
  padding: 10px;
  cursor: pointer;
}

.ccFoot-item:first-child{
  border-radius: 10px 10px 0 0;
}

.ccFoot-item:last-child{
  border-radius: 0 0 10px 10px;
}

/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin: 0;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: grey;
}

/* Hide the browser's default checkbox */
.container input {
    cursor: pointer;
    height: 25px;
    width: 25px;
}
.rdrInputRange {
  align-items: start;
  padding: 5px 20px;
  font-size: 15px;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 1px solid #80808094;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #ffffff;
  border: 1px solid #80808094;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

.container input:checked ~ .sh-textCol {
  color: #000;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 7px;
  top: 1px;
  width: 9px;
  height: 18px;
  border: solid #00ca72;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.sh-grey{
  color: grey;
}

.search-dashboard{
  border: none;
  width: 97%;
  color: #292f4c;
  font-weight: bold;
}

.search-dashboard::placeholder{
  color: #292f4c;
}

.btn-dashboard{
  box-shadow: 3px 3px 5px #80808080;
  padding: 10px;
  margin-right: 10px;
  border-radius: 5px;
  color: #02025b !important;
  background: white;
  cursor: pointer;
}

.btnOrange-dash{
  background: #ff642e;
  padding: 0px 12.5px;
  color: #fff !important;
  border-radius: 5px;
  font-size: 40px;
  box-shadow: 3px 3px 5px #80808080;
}

.Kanbang-content{
  width: 100%;
  overflow: hidden;
  transition: .5s;
}

.hideCards{
  height: 55px;
}

.hideLists{
  height: 65px;
}

.Kanbang-category{
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.List-category{
  display: flex;
  align-items: flex-start;
  margin: 15px 0;
}

.Kanbang-category p, .List-category p{
  margin: 0;
  color: #02025b;
}

.Kanbang-content a{
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background: #ff3d57;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  transition: .3s;
}

.Kanbang-content a:hover{
  color: white;
  cursor: pointer;
}

.rotateBtn{
  transform: rotate(-90deg);
}

.card-content{
  display: flex;
  justify-content: space-between;
}

.card{
  width: 15%;
  background: #ff3d57;
  color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 5px;
}

.card:nth-child(2){
  background: #ff642e;
}

.card:nth-child(3){
  background: #fdab3d;
}

.card:nth-child(4){
  background: #00ca72;
}

.card:nth-child(5){
  background: #009aff;
}

.card:nth-child(6){
  background: #794fcf;
}

.card-foot a{
  background: none;
  font-size: 27px;
  border-radius: 5px;
  box-shadow: 2px 2px 3px #80808080;
}

#btn-showList{
  margin-top: 16px;
}

.table-List{
  width: 100%;
}

.table-List th, .table-List td {
  border: 15px solid #fff0;
  border-collapse: collapse;
}

.title-List{
  font-weight: 400;
  color: #02025b;
}

.grey-title{
  background: #eff0f2;
}

.centerText{
  text-align: center;
}

.firstColl{
  position: relative;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.edit-fc.not-propietary {
  background: #3dff57 !important;
}

.edit-fc{
  background: #ff3d57;
  color: white;
  padding: 10px;
  position: absolute;
  left: -28px;
  cursor: pointer;
}

.edit-fc:hover { 
  transition: 0.3s;
  border-radius: 0 5px 5px 0;
  left: 0;
}

.edit-fc:hover + .name-fc { 
  transition: 0.3s;
  left: 28px; 
}


.firstColl p{
  position: relative;
  padding: 10px;
  font-weight: 400;
  margin-left: 10px;
  transition: 0.3s;
}

.msn-fc{
  color: #ff3d57;
}

.secondColl{
  text-align: center;
  border-radius: 5px;
  color: white;
  font-weight: 400;
  padding: 10px;
}

.green{
  background: #00ca72;
}

.red{
  background: #ff3d57;
}

.orange{
  background: #ff642e;
}

.thrithColl{
  text-align: center;
}

.thrithColl img{
  width: 25px;
}

.fourthColl{
  display: flex;
}

.fourthColl p:nth-child(1){
  width: 50%;
  background: #00ca72;
  padding: 5px 15px;
  border-radius: 25px 0 0 25px;
  font-weight: 400;
}

.fourthColl p:nth-child(2){
  width: 50%;
  background: #eff0f2;
  padding: 5px 15px;
  border-radius: 0 25px 25px 0;
  text-align: end;
  font-weight: 400;
}

.hide{
  display: none;
}

.realseBtn{
  position: relative;
  z-index: 2;
}