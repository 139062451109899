.containerSelect {
    position: relative;
    width: 18em;
    min-height: 1.5em;
    border: .05em solid #777;
    display: flex;
    align-items: center;
    gap: .5em;
    padding: .5em;
    border-radius: .25em;
    outline: none;
    background-color: aliceblue;
    flex-flow: wrap;
  }
  
  .containerSelect:focus {
    border-color: hsl(200, 100%, 50%);
    /* position: fixed; */
  }
  
  .valueSelectEdit{
    flex-grow: 1;
    display: flex;
    gap: .5em;
    flex-wrap: wrap;
  }
  
  .clearBtnSelect {
    background: none;
    color: #777;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    font-size: 1.25em;
  }
  
  .clearBtnSelect:focus,
  .clearBtnSelect:hover {
    color: #333;
  }
  
  .dividerSelect {
    background-color: #777;
    align-self: stretch;
    width: .05em;
  }
  
  .caretSelect {
    translate: 0 25%;
    border: .25em solid transparent;
    border-top-color: #777;
  }
  
  .options {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
    display: none;
    max-height: 15em;
    overflow-y: auto;
    border: .05em solid #777;
    border-radius: .25em;
    width: 100%;
    left: 0;
    top: calc(100% + .25em);
    background-color: white;
    z-index: 999;
  }
  
  .options.show {
    display: block;
  }
  
  .option {
    padding: .25em .5em;
    cursor: pointer;
  }
  
  .option.selected {
    background-color: hsl(200, 100%, 70%);
  }
  
  .option.highlighted {
    background-color: hsl(200, 100%, 50%);
    color: white
  }
  
  .optionBadgeSelect {
    display: flex;
    align-items: center;
    border: .05em solid #777;
    border-radius: .25em;
    padding: .15em .25em;
    gap: .25em;
    cursor: pointer;
    background: none;
    outline: none;
  }
  
  .optionBadgeSelect:hover,
  .optionBadgeSelect:focus {
    background-color: hsl(0, 100%, 90%);
    border-color: hsl(0, 100%, 50%);
  }
  
  .optionBadgeSelect:hover > .removeBtnSelect,
  .optionBadgeSelect:focus > .removeBtnSelect{
    color: hsl(0, 100%, 50%);
  }
  
  .optionBadgeSelect > .removeBtnSelect {
    font-size: 1.25em;
    color: #777;
  }