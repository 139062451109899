.publicTable > div > div .rdt_TableHead {
  position: sticky;
  top: 0;
  z-index: 1;
}
.publicTable {
  max-height: 62vh !important;
  overflow: auto !important;
}
.publicTable::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
  border-radius: 100px !important;
}
.publicTable::-webkit-scrollbar-track {
  background: #cacacc !important;
  border-radius: 10px;
}
.publicTable::-webkit-scrollbar-thumb {
  background: #06313b !important;
  border-radius: 10px;
  height: 20px;
}
.dKvXPZ {
  justify-content: start !important;
}
@media (max-width: 1010px) {
  .publicTable > div > div > div > div {
    font-size: 11px !important;
  }
}
.publicTable .rdt_Pagination {
  justify-content: start !important;
}

.contenedor-general {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
}

.contenedorRoles {
  width: 76% !important;
}
.contenedorRoles ._main_container_1aspn_1 {
  padding: 0;
}
.kanbangRol {
  width: 96% !important;
}

.contentModalRol {
  height: 90vh !important;
}
.titleModalRol {
  margin-left: 57px;
  margin-top: 30px;
}
.bodyRoles {
  justify-content: flex-start;
  flex-direction: column !important;
  select {
    align-self: flex-end;
    margin-bottom: 20px;
    height: 30px;
    border: none;
    box-shadow: 5px 5px 15px #0000001f;
    border-radius: 8px;
  }
  .boxRoles {
    width: 100%;
    &__title {
      display: flex;
      h5 {
        width: 33.3%;
        box-shadow: 5px 5px 15px #0000001f;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 0 0 5px 5px;
        font-size: 0.8rem;
        font-weight: bold;
        background-color: #fff;
        margin-bottom: 0;
        position: relative;
        z-index: 3;
        &:nth-child(2) {
          background-color: #b7b9bb;
        }
      }
    }
    &__content {
      display: flex;
      .box {
        width: 33.3%;
        background-color: #fff;
        height: 58vh;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 20px 10px;
        &::-webkit-scrollbar {
          width: 10px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 25px;
          background: linear-gradient(
            to right,
            white,
            #f5f5f5,
            #f5f5f5,
            #f5f5f5,
            white
          );
          width: 1px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 25px;
          background: #ff642e;
        }
        input {
          border-radius: 5px;
          border: none;
          padding-left: 5px;
          box-shadow: 5px 5px 15px #0000001f;
          width: 100%;
          margin-bottom: 20px;
        }
        .boxCheck {
          display: grid;
          grid-template-columns: 25px 1fr 25px;
          //gap: 10px;
          align-items: center;
          margin-bottom: 10px;
          .check {
            width: 15px;
            height: 15px;
            margin: 0;
          }
          i {
            text-align: center;
          }
        }
      }
    }
  }
}

.btnCancelarModalRol {
  width: 250px;
  height: 40px;
  text-align: center;
  font-size: 16px !important;
}
.btnGuardarModalRol {
  width: 250px;
  height: 40px;
  text-align: center;
  font-size: 16px !important;
  background-color: #ff642e !important;
}

.boxRoles__title {
  position: relative;
}

.iconPlus {
  position: absolute;
  right: 15px;
  top: -3px;
  font-size: 1.8rem;
  font-weight: bold;
  cursor: pointer;
}
.contentModalRolUser {
  width: 46%;
  height: auto;
  .titleModalRol {
    margin-left: 35px;
  }
}

.contentModalRolAccount {
  width: 46%;
  height: auto;
}

.formOneStep {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
}

.btnCancelOS {
  color: #fff;
  font-weight: 700;
  padding: 6px 50px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.43137254901960786);
  background: #818486;
}

.btnConfirmOS {
  color: #fff;
  font-weight: 700;
  padding: 6px 50px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.43137254901960786);
  cursor: pointer;
  background: #ff642e;
}

.bodyOneStep {
  width: 90%;
  height: calc(100% - 120px);
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 20px 10px;
  overflow-x: hidden;
  padding-right: 15px;
}

.footOneStepModal2 {
  position: relative;
  bottom: -3rem;
  padding: 0 6%;
}

.footOneStep {
  width: 95%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalUsers {
  align-items: flex-start;
  .form-group {
    width: 100% !important;
  }
  label {
    width: 100%;
    font-size: 1.1rem;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    input {
      width: 100%;
      border: none;
      box-shadow: 0 2px 15px #5f6468;
      border-radius: 5px;
      padding: 3px 5px;
    }
  }
}
.modalAccount {
  padding-top: 10px;
  align-items: flex-start;
  .form-group {
    display: row;
    width: 100%;
    input {
      margin-top: 0;
    }
  }
  label {
    width: 100%;
    font-size: 1.1rem;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    gap: 40px;
    input {
      width: 60%;
      border: none;
      box-shadow: 0 2px 15px #5f6468;
      border-radius: 5px;
      padding: 3px 5px;
      margin-top: 0 !important;
    }
    select {
      width: 300px;
      border: none;
      box-shadow: 0 2px 15px #5f6468;
      border-radius: 5px;
      padding: 3px 5px;
    }
    &:nth-child(1) input {
      margin-left: 20px;
    }
  }
}

.contGeneralRoles {
  height: 100%;
  min-height: 0;
  overflow-y: auto;
  .contenedorRoles {
    width: 96% !important;
    ._main_container_1aspn_1 {
      min-width: 100%;
      max-width: 100% !important;
    }
  }
}

.kanbangRol {
  height: 100%;
  margin-top: 20px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 25px;
    background: linear-gradient(
      to right,
      white,
      #f5f5f5,
      #f5f5f5,
      #f5f5f5,
      white
    );
    width: 1px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 25px;
    background: #ff642e;
  }
}

.contRoles {
  h1 {
    font-size: 1.5rem;
    margin: 0;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .content-firstColumn {
    position: relative;
    width: 100%;
    .iconBand {
      background: #ff3d57;
      color: #fff;
      padding: 10px 0;
      position: absolute;
      cursor: pointer;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: left;
      align-items: center;
      gap: 10px;
      border-radius: 3px;
      transition: all 0.5s ease;
      padding-right: 15px;
      left: -20px;
      width: 5px;
      height: 47px;
      z-index: 999;
      i {
        display: none;
      }
      &:hover {
        width: auto;
        padding: 10px 15px;
        i {
          display: block;
        }
      }
    }
  }

  .subTablas {
    overflow-y: hidden;
    min-height: 100%;
    max-height: 2000px;
    width: 100%;
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 25px;
      background: linear-gradient(
        to right,
        white,
        #f5f5f5,
        #f5f5f5,
        #f5f5f5,
        white
      );
      width: 1px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 25px;
      background: #ff642e;
    }
  }
  p:nth-child(1) {
    border: 0.5px dotted #02025b;
    border-radius: 5px;
    padding: 3px;
  }
  .scroll_agrupaciones {
    min-height: 100px;
    max-height: 2000px;
    margin-bottom: 1rem;
    overflow-y: hidden !important;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 25px;
      background: linear-gradient(
        to right,
        white,
        #f5f5f5,
        #f5f5f5,
        #f5f5f5,
        white
      );
      width: 1px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 25px;
      background: #ff642e;
    }
    .btn-show-table {
      top: 6px;
      left: 1px;
    }
    .option-select {
      border: none;
      border-radius: 5px;
      height: 35px;
      box-shadow: 5px 5px 15px #0000001f;
    }
    .kYHwMA div:first-child {
      font-weight: normal;
    }
    .sc-hKMtZM {
      &:nth-child(2) {
        background-color: #e3e4e6;
        p {
          border: 0.5px dotted #02025b;
          border-radius: 5px;
          padding: 3px;
          font-weight: normal;
        }
      }
    }
    .kaLCam {
      overflow-y: auto;
      min-height: 100px;
      margin-top: -40px;
    }
    h2 {
      font-size: 1.5rem;
      margin: 0;
    }
  }
}

.filtro3 {
  .options-grouped {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;
    margin-bottom: 10px;
    p {
      margin: 0;
      font-size: 0.8rem;
    }
    select {
      border: none;
      box-shadow: 5px 5px 15px #0000001f;
      border-radius: 5px;
      font-size: 0.9rem;
    }
  }
}

.contentModalRolUser .form-group {
  width: 80%;
}
.swal2-shown {
  padding: 0 !important;
}
.swal2-container.swal2-center {
  padding: 0 !important;
}

@media (min-width: 1400px) {
  .contGeneralRoles {
    height: 600px;
  }
}

.modalAsigRol {
  width: 75%;
}

/*NUEVO CSS ENERO 2023*/
.main-GestionRoles {
  height: 80vh;
  ._button_add_1aspn_34 {
    i::before {
      font-size: 26px;
      bottom: 7px !important;
    }
  }
  .contaner-tables {
    height: 95%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 25px;
      background: linear-gradient(
        to right,
        white,
        #f5f5f5,
        #f5f5f5,
        #f5f5f5,
        white
      );
      width: 1px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 25px;
      background: #ff642e;
    }
    .box-table {
      height: 100%;
      h2 {
        margin: 0;
        position: relative;
        bottom: -42px;
      }
      .gghHAJ {
        &:nth-child(2) {
          background-color: #eff0f2;
        }
      }
      .sc-hKMtZM {
        &:nth-child(2) {
          p {
            border: 0.5px dotted #02025b;
            border-radius: 5px;
            padding: 3px;
            font-weight: normal;
          }
        }
      }
    }
  }
}

@media (max-height: 768px) {
  .main-GestionRoles .contaner-tables {
    height: 70vh;
  }
}
@media (max-width: 650px) {
  .bodyRoles {
    .boxRoles {
      display: grid;
      grid-template-columns: auto auto;
      position: relative;
      &__title {
        flex-direction: column;
        gap: 390px;
        position: absolute;
        top: -34px;
        h5 {
          width: 100px;
          text-align: left;
          padding-left: 5px;
          .iconPlus {
            right: 6px;
          }
        }
      }
      &__content {
        flex-direction: column;
        width: 100%;
        .box {
          width: 100%;
          height: 380px;
          margin-bottom: 40px;
        }
        .boxRoles {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  .main-GestionRoles .contaner-tables {
    height: 60vh;
    margin-top: 20px;
  }
  .modalAsigRol {
    width: 90%;
    padding: 40px 2% 0;
  }
}
