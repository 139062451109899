.react-google-flight-datepicker .dialog-date-picker.open {
  width: 600px;
  inset: 0 0 20px -40%;
}

.react-google-flight-datepicker .dialog-date-picker.open {
  margin-top: 0 !important;
}

.ModalPauta {
  width: 85% !important;
}
.modalapauta .bodyOneStep {
  height: 77%;
}
.priorityResponsive {
  width: 100%;
}
.bodyModalPauta .bodyOneStep {
  height: 77% !important;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 20px 10px;
  overflow-x: hidden;
  padding-right: 15px;
}

.twoColBody {
  grid-column-start: 1;
  grid-column-end: 3;
}

.btnConfirm {
  width: 250px;
  height: 35px;
  background-color: #ff642e;
  border-radius: 5px;
  text-decoration: none;
  color: #fff;
  text-align: center;
  line-height: 35px;
  font-weight: bold;
  font-size: 1rem;
}

.btnCancel {
  width: 250px;
  height: 35px;
  background-color: #818486;
  border-radius: 5px;
  text-decoration: none;
  color: #fff;
  text-align: center;
  line-height: 35px;
  font-weight: bold;
  font-size: 1rem;
}

.footOneStep {
  width: 95%;
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contSelect,
.contSelect .oneColBody,
.contSelect .oneColBody .form-group,
.contSelect .oneColBody .form-group > div {
  //z-index: 1 !important;
  position: relative;
  overflow: visible;
  height: auto;
}
@media (max-width: 1300px) {
  .react-google-flight-datepicker .dialog-date-picker.open {
    width: 400px !important;
    inset: 50px 0 50px -20%;
  }
}
@media (max-width: 1050px) {
  .react-google-flight-datepicker .dialog-date-picker.open {
    width: 400px !important;
    inset: 50px 0 50px -40%;
  }
}
@media (max-width: 880px) {
  .react-google-flight-datepicker .dialog-date-picker.open {
    width: 400px !important;
    inset: 50px 0 50px -50%;
  }
}
