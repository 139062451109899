.left-form{
    width: 50%;
  }

  .view-camp{
    width: 90%;
    box-shadow: 0 0 5px grey;
    border-radius: 5px;
    margin-left: 20%;
    padding: 20px;
  }
  
  .content-modal__accountLogout {
    display: flex;
    width: 35%;
    background-color: #fff;
    min-height: 300px !important;
    justify-content: flex-start;
    border-radius: 20px;
    max-height: 96vh;
  }

  .Content-componet{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    position: relative;
  }
  
  .componentModal{
    width: 16.66%;
    position: relative;
  }
  
  .title{
    color: #292f4c;
  }
  
  .input{
    width: 100%;
    border: none;
    margin-top: 20px;
    border-bottom: 1px solid grey;
  }
  
  .disa:first-child {
    color: grey !important;
  }
  
  .dataPick{
    position: relative;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid grey;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  
  .contDate{
    display: flex;
    align-items: center;
  }
  
  .calen{
    font-size: 20px;
    padding: 5px;
  }
  
  .rigth-btn__cat{
    text-align: right;
    display: flex;
    justify-content: space-between;
    margin: auto auto;;
  }
  
  .space-btns{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .space{
    margin: 20px 0 10px;
  }
  
  .padd{
    padding: 10px;
  }
  
  .btn-camp{
    background: orangered;
    color: white;
    padding: 10px 30px;
    text-decoration: none;
    margin: 20px 5px 10px;
    display: inline-block;
    border-radius: 5px;
    box-shadow: 1px 1px 5px #80808096;
    width: 45%;
    text-align: center;
    font-weight: bold;
  }

  .btn-camp-cancel {
    background: #80808096;
    color: white;
    padding: 10px 30px;
    text-decoration: none;
    margin: 20px 5px 10px;
    display: inline-block;
    border-radius: 5px;
    box-shadow: 1px 1px 5px #80808096;
    width: 45%;
    text-align: center;
    font-weight: bold;
  }
  
  .form-tar{
    margin-top: 20px;
    display: block;
    width: 100%;
    height: 700px;
  }
  .form-tar .fc-view-harness{
    height: 500px !important;
  }
  @media (max-width: 1600px){
    .form-tar{
      height: 560px;
    }
    .form-tar .fc-view-harness{
      height: 380px;
    }
  }
  .dateCvv{
    width: 98%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  #cvv::after{
    content: "?";
    color: red;
    font-weight: bold;
  }
  
  .mitad{
    width: 45%;
  }
  
  .all{
    width: 98% !important;
  }
  
  .margin{
    margin: 5px 0 20px 0 !important;
  }
  
  .noBorder{
    border: none;
    width: 95%;
  }
  
  .interogative{
    display: inline;
    color: red;
    font-weight: bold;
  }
  
  .btn-end{
    background: orangered;
    color: white;
    padding: 10px 30px;
    text-decoration: none;
    margin: 20px 5px 0 5px;
    display: inline-block;
    border-radius: 5px;
    box-shadow: 1px 1px 5px #80808096;
    text-align: center;
    font-weight: bold;
  }
  
  .gray{
    background: white;
    color: #b7b7b7;
    border: 1px solid #80808096;
  }
  
  .btn-camp:hover{
    color: #fff;
    text-decoration: none;
  }
  
  .gray:hover{
    color: #b7b7b7;
    text-decoration: none;
  }
  
  .DateRangePickerInput__withBorder{
    border: none;
  }
  
  .CalendarDay__selected_span {
    background: #ff642e; 
    color: white; 
    border: 1px solid #ff642e; 
  }
  
  .CalendarDay__selected {
    background: #ff4e0e;
    color: white;
    border: 1px solid red; 
  }
  
  .CalendarDay__selected_span:hover {
    background: orange; 
    color: white;
    border: 1px solid red;
  }
  
  
  .CalendarDay__selected:hover {
    background: orange;
    color: white;
  }
  
  .tagsContent{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
  }
  
  .tagCont{
    background: #ff3d57;
    margin: 5px;
    padding: 0 10px 0 15px;
    border-radius: 25px;
    color: white;
    display: flex;
    align-items: center;
  }
  
  .tagCont p{
    margin: 0;
    padding-right: 20px;
  }
  
  .require::placeholder{
    color: #ff3d57;
  }
  
  .tagCont:nth-child(2){
    background: #ff642e;
  }
  
  .tagCont:nth-child(3){
    background: #fdab3d;
  }
  
  .tagCont:nth-child(4){
    background: #00ca72;
  }
  
  .tagCont:nth-child(5){
    background: #009aff;
  }
  
  .tagCont:nth-child(6){
    background: #794fcf;
  }
  
  .tagCont:nth-child(7){
    background: #cacacc;
  }
  
  .tagCont:nth-child(8){
    background: #292f4c;
  }
  
  .noText {
    border: 1px solid #ff3d57 !important;
    color: #ff3d57;
  }
  
  .noText::placeholder{
    color: #ff3d57;
  }
  
  .cvv::placeholder{
    color: #ff3d57;
  }

  .form{
    height: calc(100vh - 60px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .form-margin__cat{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  a:hover{
    text-decoration: none;
    color: #fff;
  }
  
  .wcam8{
    width: 800%;
  }
  
  .wcam7{
    width: 700%;
  }
  
  .Subtitle-camp{
    color: gray;
    font-weight: bold;
  }
  
  contenedorCrearCampSinReg__cat{
    /* height: calc(100vh - 90px); */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    height: auto;
  }
  
  contenedorCrearCampSinReg__cat .bodyOneStepPublico{
    height: auto !important;
    margin: 0 auto;
    width: 100%;
  }
  contenedorCrearCampSinReg__cat .form-margin__cat{
    height: 100%;
  }
  contenedorCrearCampSinReg__cat .view-camp{
    width: 70%;
  }
  
  contenedorCrearCampSinReg__cat .title-view{
    width: 100%;
  }
  
  contenedorCrearCampSinReg__cat .fecha-view{
    width: 100%;
  }
  
  contenedorCrearCampSinReg__cat .open{
    inset: -120px 0 0 0 !important;
    width: 750px !important;
  }
  
  @media (max-width: 900px){
    contenedorCrearCampSinReg__cat .form-margin__cat{
      flex-direction: column;
      width: 90%;
      gap: 40px;
    }
    contenedorCrearCampSinReg__cat .form-margin__cat .left-form{
      width: 100%;
      padding-top: 30px;
    }
    contenedorCrearCampSinReg__cat .form-margin__cat .right-form{
      width: 100%;
      padding-bottom: 30px;
  
    }
    contenedorCrearCampSinReg__cat .form-margin__cat .right-form .view-camp{
      margin: 0 auto;
    }
  }
  
  
  
  