.content-modal {
  width: 85%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.modalOneDrive {
  width: 80%;
  height: 80vh;

  .body-modal {
    height: 55vh;
    justify-content: flex-start;
    padding-top: 0;
    align-items: flex-start;
  }
}
.header-modal {
  width: 90%;
  height: 80px;
  margin: 30px auto 0;
  .titleModal {
    margin: 0;
    display: inline-block;
    font-size: 1.8rem;
    font-weight: bold;
  }
  i {
    margin: 0 !important;
    padding: 0;
  }
}
.bodyModalOneDrive {
  display: flex;
  flex-direction: row;
  .body-part {
    width: 50% !important;
    h4 {
      text-align: center;
    }
  }
}
.body-modal {
  overflow-y: auto;
  height: 45vh;

  &::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #ff642e, #ff642e);
    border-radius: 20px;
  }
  &::-webkit-scrollbar-track {
    background: linear-gradient(90deg, #ccc, #c2c2c2 1px, #ccc 0, #b1b1b1);
  }
  .contenedor1 {
    width: 100%;
    input {
      width: 100%;
      border: none;
      box-shadow: 0 2px 5px rgba($color: #000000, $alpha: 0.3);
      background-color: #fff;
      height: 40px;
      margin-bottom: 20px;
    }
    .css-g1d714-ValueContainer {
      height: 100%;
    }
    .css-b8ldur-Input {
      height: 100%;
      margin-left: -7px;
    }
    h6 {
      margin-top: 20px;
      font-size: 18px;
      font-weight: bold;
    }
    .listaUsuarios {
      list-style: none;
      width: 100%;
      padding: 0;
      margin: 0;

      .item-lista {
        display: flex;
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: #ff3d57;
          grid-area: area1;
        }
        div {
          width: calc(100% - 130px);
        }
        .userName {
          font-weight: bold;
          padding-left: 10px;
          margin: 0;
        }
        .email {
          padding-left: 10px;
          margin: 0;
        }
        .rol {
          width: 70px;
          font-weight: bold;
        }
      }
    }
  }
  .contenedor2 {
    width: 100%;
    margin-top: 30px;
    h6 {
      font-size: 18px;
      font-weight: bold;
    }
    .containerGeneral {
      display: flex;
      align-items: center;
      i {
        margin-right: 7px;
      }
      a {
        font-size: 16px;
      }
      button {
        border-radius: 5px;
        border: none;
        box-shadow: 0 2px 12px rgba($color: #000000, $alpha: 0.4);
        margin-left: 50px;
      }
    }
  }
}
.footer-Modal {
  padding: 20px 5%;
  display: flex;
  justify-content: space-between;
  .btn {
    width: 240px;
    height: 40px;
    border-radius: 5px;
    font-size: 1rem;
    padding: 0;
    color: #fff;
    font-weight: bold;
    &_cancel {
      background-color: #7e7e80;
    }
    &_save {
      background-color: #ff642e;
    }
  }
}
.content-firstColumn {
  position: relative;
  width: 100%;
  .name-fc {
    font-weight: normal;
    color: #000000de;
  }
  .iconBand {
    background: #ff3d57;
    color: #fff;
    padding: 10px 0;
    position: absolute;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;
    border-radius: 3px;
    transition: all 0.5s ease;
    padding-right: 15px;
    left: -20px;
    width: 5px;
    height: 47px;
    z-index: 999;
    i {
      display: none;
    }
    &:hover {
      width: auto;
      padding: 10px 15px;
      i {
        display: block;
      }
    }
  }
}

@media (max-width: 758px) {
  .modalLink {
    width: 90%;
    .footer-Modal {
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
  }
  .body-modal .contenedor2 .containerGeneral button {
    margin-left: 30px;
    margin-bottom: 20px;
  }
  .body-modal .contenedor2 .containerGeneral a {
    margin-bottom: 20px;
  }
  .body-modal .contenedor2 .containerGeneral i {
    margin-bottom: 20px;
  }
  .body-modal .contenedor2 .containerGeneral {
    flex-wrap: wrap;
  }
}

.boxLogOut {
  width: 100%;
  display: flex;
  justify-content: right;
}
