.levels {
    &__title {
      display: flex;
      align-items: center;
      flex-direction: column;
      background-color: #ff642e;
      color: #fff;
      font-weight: bold;
      font-size: 2rem;
      // padding-bottom: 1.5rem;
      margin-left: 2rem;
      // justify-content: flex-end;
      height: 100%;
      // margin-right: 10px;
      // width: 200px;
    }
  
    &__title-complete-column {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
    
    &__expandable-component {
      display: grid;
      grid-template-columns: 250px auto;
      border: 1px 1px 0 1px solid #000;
      position: relative;
      z-index: 1;
    }
  
    // &__main-container {
    //   display: flex;
    //   flex-direction: column;
    //   align-items: flex-end;
    // }
  
    &__arrow {
      border: solid #fff;
      border-width: 0 4px 4px 0;
      display: inline-block;
      padding: 0.4rem;
    }
  
    &__arrow-up {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }
    &__second-title {
      background-color: #05252d;
      margin-left: 3rem;
      font-size: 1.3rem;
      // margin-right: 10px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      width: auto;
      margin-left: 0;
      margin-right: 0;
      padding-bottom: 0;
    }
    &__expandable-component-only {
      display: grid;
      grid-template-columns: 180px 1fr;
      // height: 200px;
      // position: relative;
      // z-index: 14000;
      // margin-left: -180px;
      overflow-y: auto;
      // direction: rtl;
      // padding-left: 10px;
      border: 1px solid #000;
    }
  
    &__expandable-component-only::-webkit-scrollbar-thumb {
      background: linear-gradient(180deg, #05252d, #05252d);
      border-radius: 30px;
    }
  
    &__expandable-component-only::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      margin: 10px;
      padding: 10px;
      border-radius: 30px;
    }
  
    &__expandable-component-only::-webkit-scrollbar-track {
      background: linear-gradient(90deg, #ccc, #c2c2c2 1px, #ccc 0, #b1b1b1);
      border-radius: 30px;
    }
  }
  
  .level {
    &__title-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__footer-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 8px 1rem;
    }
    &__footer-separator {
      height: 1px;
      width: 100%;
      background-color: #fff;
      margin: 0;
      padding: 0;
    }
    &__footer-total {
      display: flex;
      gap: 20px;
      text-align: center;
      justify-content: center;
      align-items: center;
    }
    &__second-title-container {
      margin-top: 20px;
    }
    &__title-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 2rem;
    }
  }
  