@media screen and (max-width: 900px) {
    .component h1.title {
      margin-top: 10rem !important;
      font-size: 2rem !important;
    }
  }
  
  @media screen and (max-width: 420px) {
    .component h1.title {
      margin-top: 12rem !important;
      font-size: 1.6rem !important ;
    }
  }
  
  @media screen and (max-width: 237px) {
    .component h1.title {
      font-size: 1rem !important;
    }
  }
  