@media screen and (max-width: 900px) {
    .title {
      margin-top: 10rem !important;
      font-size: 2.5rem !important;
    }
  }
  
  @media screen and (max-width: 440px) {
    .title {
      margin-top: 10rem !important;
      font-size: 1.6rem !important;
    }
  }
  