.buttons_group_actions {
    display: flex;
    justify-content: space-between;
}

.content-templates .content-menu .barraSuperior .ant-select .ant-select-selector {
    border: none;
    align-items: center;
}

.custom-close-button{
    display: none;
}

.select-templates-selector {
    width: 15%;
    border: none;
    border-radius: 5px;
    box-shadow: 3px 3px 5px #80808080;
    padding: 10px;
    color: #292f4c;
    font-weight: bold;
    align-items: center; 
  }

  .barraSuperior .inputBusqueda {
    width: 200px; /* Ajusta este valor al ancho deseado */
    margin-left: auto; /* Esto empuja el input a la derecha */
  }
  
  /* Si necesitas ajustar el contenedor padre para asegurar la alineación correcta */
  .barraSuperior {
    display: flex;
    justify-content: flex-end; /* Alinea todo el contenido a la derecha */
  }

  .barraSuperior ._text_filter_1aspn_7 form {
    width: 100%;
  }

  .homeSinLogin__{
    height: 50vh !important;
  }
  