.modalCreateRol {
  width: 75%;
  height: 95%;
  padding-top: 40px;
  .titleModal {
    margin: 0 6%;
  }
  .body-modal {
    padding: 20px 6% 0;
    justify-content: flex-start;
    flex-direction: column;
    .rol-options {
      width: 100%;
      display: grid;
      grid-template-columns: 130px auto;
      align-items: center;
      gap: 10px;
      h3 {
        font-size: 1rem;
        margin: 0;
      }
      select {
        width: 220px;
        box-shadow: 5px 5px 15px #0000001f;
        border: none;
        height: 30px;
        border-radius: 5px;
      }
      input {
        width: 220px;
        box-shadow: 5px 5px 15px #0000001f;
        border: none;
        height: 30px;
        border-radius: 5px;
      }
    }
    .container-permiss {
      width: 100%;
      margin-top: 40px;
      display: grid;
      grid-template-columns: repeat(4, 200px);
      gap: 10px;
      justify-content: space-between;
      overflow-y: auto;
      padding: 10px 0;
      &::-webkit-scrollbar {
        width: 7px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 25px;
        background: linear-gradient(
          to right,
          white,
          #f5f5f5,
          #f5f5f5,
          #f5f5f5,
          white
        );
        width: 1px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 25px;
        background: #ff642e;
      }
      .container-acc {
        box-shadow: 5px 5px 15px #0000001f;
        background-color: rgb(238, 235, 235);
        border-radius: 5px;
        padding: 10px 15px;
        height: 250px;
        .names {
          text-align: center;
          font-size: 0.9rem;
          font-weight: bold;
        }
        .container-options {
          height: 80%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          div {
            display: flex;
            gap: 10px;
            align-items: center;
          }
        }
      }
    }
  }
}

.contAccount {
  .content-firstColumn {
    position: relative;
    width: 100%;
    .name-fc {
      font-weight: normal;
      color: #000000de;
    }
    .iconBand {
      background: #ff3d57;
      color: #fff;
      padding: 10px 0;
      position: absolute;
      cursor: pointer;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: left;
      align-items: center;
      gap: 10px;
      border-radius: 3px;
      transition: all 0.5s ease;
      padding-right: 15px;
      left: -20px;
      width: 5px;
      height: 47px;
      z-index: 999;
      i {
        display: none;
      }
      &:hover {
        width: auto;
        padding: 10px 15px;
        i {
          display: block;
        }
      }
    }
  }
  .ExportBtn {
    ._file_item_1aspn_80 {
      position: relative;
      &::after {
        content: "Exportar a excel";
        position: absolute;
        background-color: #002448;
        color: #fff;
        padding: 5px;
        font-size: 0.7rem;
        border-radius: 2px;
        top: -50px;
        left: -3px;
        text-align: center;
        visibility: hidden;
        transition: all 0.5s ease;
      }
      &::before {
        content: "";
        width: 18px;
        height: 18px;
        background-color: #002448;
        position: absolute;
        bottom: -10px;
        transform: rotate(45deg);
        z-index: -1;
        left: 14px;
        top: -23px;
        visibility: hidden;
        transition: all 0.5s ease;
      }
    }
    ._file_item_1aspn_80:hover {
      &::after {
        visibility: visible;
      }
      &::before {
        visibility: visible;
      }
    }
    ._general_item_1aspn_117 {
      position: relative;
      &::after {
        content: "Exportar a CSV";
        position: absolute;
        background-color: #002448;
        color: #fff;
        padding: 5px;
        font-size: 0.7rem;
        border-radius: 2px;
        top: -35px;
        left: -3px;
        z-index: 3;
        text-align: center;
        visibility: hidden;
        transition: all 0.2s ease;
      }
      &::before {
        content: "";
        width: 18px;
        height: 18px;
        background-color: #002448;
        position: absolute;
        bottom: -10px;
        transform: rotate(45deg);
        z-index: 1;
        left: 22px;
        top: -23px;
        visibility: hidden;
        transition: all 0.2s ease;
      }
    }
    ._general_item_1aspn_117:hover {
      &::after {
        visibility: visible;
      }
      &::before {
        visibility: visible;
      }
    }
  }
}

@media (max-width: 1280px) {
  .modalCreateRol {
    .body-modal {
      .container-permiss {
        grid-template-columns: repeat(3, 200px);
      }
    }
  }
}

@media (max-width: 950px) {
  .modalCreateRol {
    .body-modal {
      .container-permiss {
        grid-template-columns: repeat(2, 200px);
      }
    }
  }
}
@media (max-width: 650px) {
  .modalCreateRol {
    width: 90%;
    .titleModal {
      text-align: center;
      font-weight: bold;
    }
    .body-modal {
      .rol-options {
        grid-template-columns: auto;
      }
      .container-permiss {
        grid-template-columns: repeat(1, 200px);
        justify-content: center;
      }
    }
  }
  .buttons-Modal-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .btnCancelarModalRol {
      width: 120px;
    }
    .btnGuardarModalRol {
      width: 120px;
    }
  }

  /*NUEVO CSS*/
  .mainRolSistema {
    .containerTable {
      margin-top: 30px;
    }
  }
}
