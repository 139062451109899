
.form{
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.form-margin{
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subTitle{
  justify-content: center;
  color: #868688;
  font-weight: bold;
  margin: 0;
  text-align: center;
}

.title{
  text-align: center;
  justify-content: center;
  color: #292f4c;
  margin: 0;
  padding-bottom: 0.5em;
}

.btn-plus{
  padding: 2px 14px;
  text-decoration: none;
  color: white;
  background: orangered;
  box-shadow: 0 0 10px orangered;
  border-radius: 10px;
  font-weight: bold;
  font-size: 40px;
  margin-left: 45%;
  
}
.homeSinLogin{
  height: 70vh !important;
}
@media (max-width: 768px){
  .homeSinLogin{
    height: calc(100vh - 90px) !important;
  }
}