.right-form{
  width: 50%;
}

.view-camp{
  width: 90%;
  box-shadow: 0 0 5px grey;
  border-radius: 5px;
  margin-left: 20%;
  padding: 20px;
}

.ene-view{
  background: #292f4c;
  border-radius: 25px;
  color: #292f4c;
}

.insert{
  color: #292f4c;
  font-weight: bold;
}

.red-view{
  width: 32%;
  font-weight: 700;
  color: #292f4c;
}

.title-view{
  margin-bottom: 20px;
  width: 70%;
  font-weight: 700;
  color: #292f4c;
}

.dis-view{
  background: #b7b7b7;
  border-radius: 25px;
  color: #b7b7b7;
}

.fecha-view{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  width: 70%;
}

.dat{
  width: 45%;
  margin: 10px 0;
}

.tag{
  width: 30%;
  margin-bottom: 10px;
}

.color{
  background: #ff3d57;
  border-radius: 25px;
  color: #ff3d57;
}

.color:nth-child(2){
  background: #ff642e;
  border-radius: 25px;
  color: #ff642e;
}

.color:nth-child(3){
  background: #fdab3d;
  border-radius: 25px;
  color: #fdab3d;
}

.color:nth-child(4){
  background: #00ca72;
  border-radius: 25px;
  color: #00ca72;
}

.color:nth-child(5){
  background: #009aff;
  border-radius: 25px;
  color: #009aff;
}

.color:nth-child(6){
  background: #794fcf;
  border-radius: 25px;
  color: #794fcf;
}
.color:nth-child(7){
  background: #cacacc;
  border-radius: 25px;
  color: #cacacc;
}

.color:nth-child(8){
  background: #292f4c;
  border-radius: 25px;
  color: #292f4c;
}