.formContainer {
  height: calc(100vh - 90px) !important;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #ff642e, #ff642e);
    border-radius: 20px;
  }
  &::-webkit-scrollbar-track {
    background: linear-gradient(90deg, #ccc, #c2c2c2 1px, #ccc 0, #b1b1b1);
  }
}
.containerFlex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  width: 80%;
  height: 70vh;
  .profile {
    width: 50%;
    .form-tar {
      height: auto;
      .divInput {
        width: 100%;
      }
      .textoPais {
        position: static;
        height: 82px;
        margin-top: 20px;
        padding-top: 50px;
        display: block;
      }
    }
    .contBtns {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 3rem;
      margin-bottom: 2rem;
      a {
        width: 250px !important;
        height: 40px !important;
        padding: 0 !important;
        line-height: 40px;
        margin: 0 !important;
        cursor: pointer !important;
      }
      .btn2 {
        width: 250px !important;
        height: 40px !important;
        padding: 0 !important;
      }
    }
  }
  .info {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    justify-content: center;
    .boton {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
    }
    .btn-plus {
      margin: 0;
      cursor: pointer;
    }
    .logout-text {
      margin: 0;
    }
  }
}

@media (min-width: 1600px) {
  .containerFlex {
    width: 90%;
  }
}

@media (max-width: 1285px) {
  .containerFlex .profile .contBtns {
    flex-direction: column;
    gap: 25px;
    a {
      width: 100% !important;
    }
    .btn2 {
      width: 100% !important;
    }
  }
}

@media (max-width: 768px) {
  .formContainer {
    align-items: flex-start;
    padding-top: 1.5rem;
  }
  .containerFlex {
    align-items: flex-start;
    gap: 30px;
    .profile {
      width: 90%;
    }
    .info {
      width: 100%;
    }
  }
}
