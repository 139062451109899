.react-google-flight-datepicker .dialog-date-picker.open {
  width: 600px;
  inset: 0 0 20px -40%;
}

.react-google-flight-datepicker .dialog-date-picker.open {
  margin-top: 0 !important;
}

.ModalPauta {
  width: 85% !important;
}

@media (max-width: 1300px) {
  .react-google-flight-datepicker .dialog-date-picker.open {
    width: 400px !important;
    inset: 50px 0 50px -20%;
  }
}
@media (max-width: 1050px) {
  .react-google-flight-datepicker .dialog-date-picker.open {
    width: 400px !important;
    inset: 50px 0 50px -40%;
  }
}
@media (max-width: 880px) {
  .react-google-flight-datepicker .dialog-date-picker.open {
    width: 400px !important;
    inset: 50px 0 50px -50%;
  }
}

.btnConfirm {
  width: 250px;
  height: 35px;
  background-color: #ff642e;
  border-radius: 5px;
  text-decoration: none;
  color: #fff;
  text-align: center;
  line-height: 35px;
  font-weight: bold;
  font-size: 1rem;
}

.btnCancel {
  width: 250px;
  height: 35px;
  background-color: #818486;
  border-radius: 5px;
  text-decoration: none;
  color: #fff;
  text-align: center;
  line-height: 35px;
  font-weight: bold;
  font-size: 1rem;
}
