@media screen and (max-width: 1600px){
    .form-tar {
        height: 100% !important;
    }
}

@media screen and (max-width: 900px){
    .form-margin {
        overflow-y: auto;
    }
}