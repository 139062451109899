.header{
  position: fixed;
  width: 100%;
  z-index: 99;
}
.navbar {
  height: 60px;
  background: #fff;
  color: #fff;
  box-shadow: 0 0 15px hsla(0, 0%, 50.2%, .65);
  display: flex;
  justify-content: space-evenly;
  z-index: 15;

  .container-fluid{
    position: absolute !important;
    top: 0;
    left: 0;
  }

  .container,
  .navbar-brand {
    height: 100%;
  }

  .logo2{
    display: none;
  }
  .container {
    max-width: 100%;
  }

  .nav-link {
    font-size: 1rem;
    font-weight: 700;
  }

  .nav-options {
    .tab.nav-item {
      margin: 0 8px;
    }
  }

  .account-selector-container {
    font-size: 0.8rem;
    min-width: 160px;
    font-weight: bold;

    .account-selector__control {
      border: none;
      box-shadow: none;
    }

    .account-selector__value-container {
      justify-content: center;
    }
  }
}

@media (max-width: 1133px) {


  .navbar 
  .container-fluid {
    margin: 0 !important;
    padding: 0 !important;
    .container{
      flex-direction: column !important;
      align-items: flex-start;
    }
  }
  

  .navbar-brand{
    width: auto;
    height: 60px;
    img{
      width: 80px;
    }
  }
}
@media (max-width: 767px){
  .container-fluid {
    .container{
      flex-direction: row;
      justify-content: space-between !important;
      .navbar-brand{
        .logo1{
          display: block !important;
          width: 120px;
          height: 60px !important;
          position: static;
          margin-left: 20px;
          margin-top: 15px;
        }
        .logo2{
          display: none !important;
        }
      }
      .navbar-toggler{
        margin-left: calc(100% - 60px);
      }
    }
  }
    
}
