.shaddow{
    position: absolute;
    z-index: 6;
    width: 100%;
    height: 100%;
    background: #000000ad;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalD{
    width: 90%;
    height: 90%;
    background: #fff;
    border-radius: 5px;
}

.modalD h3{
    margin-left: 10px;
    display: inline-block;
}

.modalD i{
    font-size: 30px;
    padding: 5px 10px;
    cursor: pointer;
}

.scrollModal{
    height: 90%;
    overflow-y: scroll;
}

.itemModal{
    margin: 0px 15px 7px;
}

.itemModal p{
    margin: 0;
    font-weight: 500;
}
.imgURL{
    width: 100%;
    height: 250px;
}
.card-group{
    margin: 0.5em;
}
.div-Main>div{
    border: 5px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin: 1em auto;
    padding: 1em;
    display: grid;
    grid-auto-rows: auto;
    grid-gap: 1.5em;
}
.div-son{
    border-width: 2px;
    border-style: solid;
    border-color: darkgray;
    
}
.App {
  font-family: sans-serif;
  text-align: center;
}

.App {
  max-width: 800px;
  grid-gap: 10px;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(4, auto);
  align-items: center;
  justify-items: center;
}

.AppImage {
  padding: 5px;
  min-height: 200px;
  min-width: 250px;
}
