//
// Typography
//

// Font Families
$g-font-primary: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
$g-font-secondary: "Roboto Slab", Helvetica, Arial, sans-serif !default;
$g-font-code: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;

// Font Sizes
$g-font-primary-size: 14px !default;

//
// Colors
//

// Main Colors
$g-color-main: #555 !default;
$g-bg-color-main: #fff !default;

// Primary Color
$g-color-primary: #72c02c !default;
$g-color-primary-dark-v1: darken($g-color-primary, 2%) !default;
$g-color-primary-dark-v2: darken($g-color-primary, 5%) !default;
$g-color-primary-dark-v3: darken($g-color-primary, 10%) !default;
$g-color-primary-light-v1: rgba($g-color-primary, .2) !default;
$g-color-primary-light-v2: rgba($g-color-primary, .8) !default; // R

// Secondary Color
$g-color-secondary: #e74b3c !default;
$g-bg-color-secondary: #fafafa !default;

// Color Black
$g-color-black: #000 !default;

// Color White
$g-color-white: #fff !default;