.simpleText {
  .texto {
    width: 100% !important;
  }
}

.ContentReport .barraSuperiorReport {
  ._main_container_1aspn_1 {
    width: 80% !important;
    min-width: 80% !important;
  }
  .select-workspace {
    width: 16%;
    margin-right: 2%;
  }
}
