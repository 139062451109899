.form{
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contenedorCrearCampSinReg__{
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.form-margin{
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

a:hover{
  text-decoration: none;
  color: #fff;
}

.wcam8{
  width: 800%;
}

.wcam7{
  width: 700%;
}

.Subtitle-camp{
  color: gray;
  font-weight: bold;
}

.contenedorCrearCampSinReg{
  height: calc(100vh - 90px);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.contenedorCrearCampSinReg .bodyOneStepPublico{
  height: auto !important;
  margin: 0 auto;
  width: 100%;
}
.contenedorCrearCampSinReg .form-margin{
  height: 100%;
}
.contenedorCrearCampSinReg .view-camp{
  width: 70%;
}

.contenedorCrearCampSinReg .title-view{
  width: 100%;
}

.contenedorCrearCampSinReg .fecha-view{
  width: 100%;
}

.contenedorCrearCampSinReg .open{
  inset: -120px 0 0 0 !important;
  width: 750px !important;
}

@media (max-width: 900px){
  .contenedorCrearCampSinReg .form-margin{
    flex-direction: column;
    width: 90%;
    gap: 40px;
  }
  .contenedorCrearCampSinReg .form-margin .left-form{
    width: 100%;
    padding-top: 30px;
  }
  .contenedorCrearCampSinReg .form-margin .right-form{
    width: 100%;
    padding-bottom: 30px;

  }
  .contenedorCrearCampSinReg .form-margin .right-form .view-camp{
    margin: 0 auto;
  }
}