.main__cuentas {
    height: 100%;
    padding-left: 20px;
    .barraSuperior {
      display: flex;
      flex-wrap: wrap;
      select {
        width: 140px;
        height: 42.5px;
        margin-right: 10px;
        border: none;
        box-shadow: 0px 1px 5px rgb(128 128 128 / 50%);
        border-radius: 5px;
      }
      ._main_container_1aspn_1 {
        padding: 0 0 10px;
        ._button_add_1aspn_34 {
          width: 46px;
          height: 42.5px;
          i::before {
            position: relative;
            bottom: 5px;
          }
        }
      }
    }
  
    .content-modal {
      width: 85%;
      height: 70vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .header-modal {
      width: 90%;
      height: 80px;
      margin: 30px auto 0;
      .titleModal {
        margin: 0;
        display: inline-block;
        font-size: 1.8rem;
        font-weight: bold;
      }
      i {
        margin: 0 !important;
        padding: 0;
      }
    }
    .body-modal {
      overflow-y: auto;
      height: 45vh;
      &::-webkit-scrollbar {
        width: 5px;
        height: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: linear-gradient(180deg, #ff642e, #ff642e);
        border-radius: 20px;
      }
      &::-webkit-scrollbar-track {
        background: linear-gradient(90deg, #ccc, #c2c2c2 1px, #ccc 0, #b1b1b1);
      }
      .body-part {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        width: 100%;
        grid-column-gap: 10%;
        height: 80%;
        align-items: flex-start;
        .label-container {
          display: flex;
          flex-direction: column;
          font-weight: bold;
          position: relative;
          .fa-eye,
          .fa-eye-slash {
            position: absolute;
            bottom: 8px;
            right: 10px;
          }
          select {
            border: none;
            height: 30px;
            box-shadow: 5px 5px 15px #00000029;
            border-radius: 5px;
          }
          input {
            border: none;
            height: 30px;
            box-shadow: 5px 5px 15px #00000029;
            border-radius: 5px;
          }
        }
      }
    }
    .footer-Modal {
      padding: 20px 5%;
      display: flex;
      justify-content: space-between;
      .btn {
        width: 240px;
        height: 40px;
        border-radius: 5px;
        font-size: 1rem;
        padding: 0;
        color: #fff;
        font-weight: bold;
        &_cancel {
          background-color: #7e7e80;
        }
        &_save {
          background-color: #ff642e;
        }
      }
    }
    .content-firstColumn {
      position: relative;
      width: 100%;
      .name-fc {
        font-weight: normal;
        color: #000000de;
      }
      .iconBand {
        background: #ff3d57;
        color: #fff;
        padding: 10px 0;
        position: absolute;
        cursor: pointer;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        justify-content: left;
        align-items: center;
        gap: 10px;
        border-radius: 3px;
        transition: all 0.5s ease;
        padding-right: 15px;
        left: -20px;
        width: 5px;
        height: 47px;
        z-index: 999;
        i {
          display: none;
        }
        &:hover {
          width: auto;
          padding: 10px 15px;
          i {
            display: block;
          }
        }
      }
    }
  }
  
  .main__asociationIds {
    .content-modal {
      width: 80%;
      height: 70vh;
    }
    .body-modal {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 40px;
      padding-top: 0;
      align-items: flex-start;
      .body-part {
        height: auto;
        grid-template-columns: 1fr;
        &:nth-child(1) {
          gap: 30px;
        }
        &:nth-child(2) {
          height: auto;
        }
        p {
          font-weight: bold;
        }
        .label-container {
          width: 50%;
        }
        .containerBoxes {
          box-shadow: 0px 1px 5px rgb(128 128 128 / 50%);
          background-color: #fff;
          display: flex;
          flex-wrap: wrap;
          height: 200px;
          flex-direction: column;
          padding: 15px;
          gap: 10px;
          .boxLabel {
            display: flex;
            width: 110px;
          }
        }
      }
    }
  }
  
  @media (min-width: 1200px) {
    .main__cuentas .barraSuperior ._main_container_1aspn_1 {
      max-width: 100% !important;
    }
  }
  
  @media (max-width: 1024px) {
    .main__asociationIds .body-modal {
      grid-template-columns: 100%;
    }
  }
  
  @media (max-width: 768px) {
    .main__cuentas {
      .barraSuperior {
        margin: 0;
        gap: 10px;
        ._main_container_1aspn_1 {
          flex-wrap: wrap;
          grid-gap: 10px;
          gap: 10px;
          justify-content: left;
          ._buttons_container_1aspn_11 {
            margin: 0;
          }
        }
      }
  
      .content-modal {
        width: 90%;
        height: 90vh;
        padding-top: 20px;
        .titleModal {
          text-align: center;
          font-size: 28px;
          width: 100%;
        }
        .body-modal {
          height: 100%;
          .body-part {
            grid-template-columns: 100%;
          }
        }
        .footer-Modal {
          flex-direction: column;
          gap: 20px;
          align-items: center;
        }
      }
    }
    .main__asociationIds .body-modal .body-part .containerBoxes {
      flex-wrap: nowrap;
      align-items: center;
      height: auto;
    }
    .main__asociationIds .body-modal .body-part .label-container {
      width: 90%;
    }
  }
  
  .account-selector__menu {
    z-index: 9 !important;
  }

  .select__vista__ {
    cursor: pointer;
    height: 90%;
  } 

  .account-selector__control.css-yk16xz-control {
    height: 2.7rem;
  }

  .select__vista__ {
    width: 15%;
    border: none;
    border-radius: 5px;
    box-shadow: 3px 3px 5px #80808080;
    padding: 5px;
    color: #292f4c;
    font-weight: bold;
    align-items: center; 
  }

  .ant-select-selector{
    border: none !important;
  }