.dropdown-header {
    border-bottom: 1px solid #f1f1f1;
    padding: 15px 20px;
    z-index: 9999;
    width: 100%;
    background-color: #f9f9f9
}

.rdrCalendarWrapper {
    padding: 40px 34px 0px 36px !important
}

.calendar-dropdown {
    position: absolute;
    top: 100%;
    right: auto;
    width: auto;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    inset: 40px -12px -1220% -268% !important;
    z-index: 9999;
    display: block;
    white-space: nowrap;

}

.calendar-footer {
    position: absolute;
    margin-top: 10px;
    right: 36px;
    width: 100%; /* Si quieres que ocupe todo el ancho */
    display: flex;
    justify-content: flex-end;
  }
  #id_btn_apply{
    background-color: #ff642e;
    border: none;
  }