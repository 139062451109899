.main_emails {
  .sc-hHLeRK {
    &::-webkit-scrollbar {
      width: 3px;
      height: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: linear-gradient(180deg, #ff642e, #ff642e);
      border-radius: 20px;
    }
    &::-webkit-scrollbar-track {
      background: linear-gradient(90deg, #ccc, #c2c2c2 1px, #ccc 0, #b1b1b1);
    }
  }
  .barraSuperior {
    .btn-sendEmail {
      height: 42.5px;
      width: 100px;
      font-size: 14px;
      border: none;
      border-radius: 5px;
      color: #000;
      background-color: #fff;
      box-shadow: 5px 5px 15px #00000029;
    }
  }
  .content-firstColumn {
    position: relative;
    width: 100%;
    .name-fc {
      font-weight: normal;
      color: #000000de;
    }
    .iconBand {
      background: #ff3d57;
      color: #fff;
      padding: 10px 0;
      position: absolute;
      cursor: pointer;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: left;
      align-items: center;
      gap: 10px;
      border-radius: 3px;
      transition: all 0.5s ease;
      padding-right: 15px;
      left: -20px;
      width: 5px;
      height: 47px;
      z-index: 999;
      i {
        display: none;
      }
      &:hover {
        width: auto;
        padding: 10px 15px;
        i {
          display: block;
        }
      }
    }
  }

  /*Modal*/
  .content-modal {
    width: 90%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .header-modal {
    width: 90%;
    height: 80px;
    margin: 0 auto;
    margin-top: 30px;
    .titleModal {
      width: 100%;
      margin: 0;
      display: inline-block;
      font-size: 1.8rem;
      font-weight: bold;
    }
    i {
      margin: 0 !important;
      padding: 0;
    }
  }
  .body-modal {
    display: grid;
    grid-template-columns: 45% 55%;
    padding: 30px 5%;
    height: 76%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
      height: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: linear-gradient(180deg, #ff642e, #ff642e);
      border-radius: 20px;
    }
    &::-webkit-scrollbar-track {
      background: linear-gradient(90deg, #ccc, #c2c2c2 1px, #ccc 0, #b1b1b1);
    }
    .body-part {
      display: flex;
      flex-direction: column;
      gap: 10px;
      height: 100%;
      &:nth-child(2) {
        align-items: flex-end;
      }
      .addButton {
        width: 150px;
        height: 35px;
        border: none;
        border-radius: 5px;
        color: #fff;
        box-shadow: 5px 5px 15px #0000001f;
        font-weight: bold;
        background-color: #ff642e;
        margin-top: 10px;
      }
      .containerLabels {
        width: 100%;
        display: grid;
        grid-template-areas: "area1 area2 area3 area4 area5 area6";
        border-bottom: 1px solid #7e7e80;
        padding-bottom: 20px;
        margin-bottom: 20px;
        .area1 {
          grid-area: area1;
        }
        .area2 {
          grid-area: area2;
        }
        .area3 {
          grid-area: area3;
        }
        .area4 {
          grid-area: area4;
          align-self: flex-end;
          justify-self: center;
          select {
            width: 40px;
            margin: 0 auto;
          }
        }
        .area5 {
          grid-area: area5;
          border: 0;
          padding: 0;
          border-radius: 5px;
          align-self: flex-end;
          text-align: center;
          font-size: 1.2rem;
        }
        .area6 {
          grid-area: area6;
          border: 0;
          padding: 0;
          font-size: 1.2rem;
          border-radius: 5px;
          align-self: flex-end;
        }
        .operador {
          select {
            width: 80px;
          }
        }
        label {
          width: 100%;
          select {
            width: 185px;
          }
          input {
            border: none;
            box-shadow: 5px 5px 15px #00000029;
            border-radius: 5px;
            height: 30px;
            width: 185px;
          }
        }
      }
      .labelAction {
        margin: 20px 0 0;
        font-weight: bold;
      }
      label {
        width: 90%;
        display: flex;
        flex-direction: column;
        font-weight: bold;
        select {
          width: 200px;
          height: 30px;
          border: none;
          border-radius: 5px;
          box-shadow: 5px 5px 15px #00000029;
        }
        .inputEmails {
          border-top: none;
          border-left: none;
          border-right: none;
        }
        .inputEmailBody {
          height: 200px;
        }
      }
      form {
        width: 90%;
      }
    }
  }
  .footer-Modal {
    padding: 20px 5%;
    display: flex;
    justify-content: space-between;
    .btn {
      width: 240px;
      height: 40px;
      border-radius: 5px;
      font-size: 1rem;
      padding: 0;
      color: #fff;
      font-weight: bold;
      &_cancel {
        background-color: #7e7e80;
      }
      &_save {
        background-color: #ff642e;
      }
    }
  }
}
.optionsBody {
  background-color: #ffffff;
  box-shadow: 5px 5px 15px #00000029;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 12px;
  margin-top: 10px;
  align-items: center;
  padding: 10px;
  width: 90%;
}
@media (max-width: 1300px) {
  .main_emails {
    .body-modal {
      .body-part {
        .containerLabels {
          grid-template-areas:
            "area1 area2 area2 area2"
            "area3 area4 area5 area6";
          justify-content: left;
          grid-column-gap: 10px;
          grid-row-gap: 10px;
          .area4 {
            width: auto;
            justify-content: left;
            select {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .main_emails {
    .titleModal {
      font-size: 28px;
      text-align: center;
    }
    .body-modal {
      grid-template-columns: 100%;
      gap: 60px;
      .body-part .containerLabels {
        grid-template-areas:
          "area1"
          "area2"
          "area3"
          "area4"
          "area5"
          "area6";
        .area4 {
          justify-self: left;
        }
        .area5 {
          justify-self: left;
        }
        .area6 {
          justify-self: left;
        }
      }
      .body-part:nth-child(2) {
        align-items: flex-start;
      }
    }
    .footer-Modal {
      flex-direction: column;
      gap: 20px;
      align-items: center;
    }
  }
}
