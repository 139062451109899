/*------------------------------------
  Custom Styles
------------------------------------*/

body {
  margin: 0;
  font-family: $g-font-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  iframe {
    position: static;
    height: calc(100vh - 90px);
  }
}

code {
  font-family: $g-font-code;
}

.dollar {
  display: flex;
  align-items: baseline;
}

.MuiAutocomplete-popper {
  z-index: 99999 !important;
}

.swal2-container {
  z-index: 99999 !important;
}
