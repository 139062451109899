@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");

body {
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}

html::-webkit-scrollbar {
  width: 10px;
  height: 20px;
}

html::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #ff642e, #ff642e);
  border-radius: 20px;
  box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25),
    inset -2px -2px 2px rgba(0, 0, 0, 0.25);
}

html::-webkit-scrollbar-track {
  background: linear-gradient(90deg, #ccc, #c2c2c2 1px, #ccc 0, #b1b1b1);
}

input:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: auto !important;
  -moz-box-shadow: auto !important;
  -webkit-box-shadow: auto !important;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

/*nuevo css*/

.form-dashboard {
  max-width: 1230px;
}

.cursor-pointer {
  cursor: pointer;
}

.shadow {
  background: rgba(0, 0, 0, 0.4392156862745098);
  position: fixed;
  z-index: 5;
  top: 0;
  height: 100vh;
}

.DdiNg {
  min-width: 150px;
}

.DdiNg > div > p {
  width: 100%;
}

.fas.fa-edit {
  margin-right: 0;
}

.catEditFind {
  background: #ff3d57;
  color: #fff;
  padding: 10px;
  position: absolute;
  cursor: pointer;
  left: auto;
  top: 6px;
  display: flex;
  justify-content: left;
  border-radius: 3px;
  right: calc(100% - 15px) !important;
  transition: all 0.5s ease;
  padding-right: 15px !important;
}
.fancybox-slide--html {
  z-index: 9999;
}
.blurNewPauta {
  z-index: 9999 !important;
}
.catEditFind:hover {
  border-radius: 0 5px 5px 0;
  left: 0;
}

.catEditFind.icons-2:hover {
  right: calc(100% - 160px) !important;
}

.catEditFind.icons-3:hover {
  right: calc(100% - 95px) !important;
}
.catEditFind.icons-4:hover {
  right: calc(100% - 170px) !important;
}

.catEditFind i {
  margin-left: 10px;
}

.DdiNg {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.kRTDwm,
.gHcsot {
  justify-content: center;
}

._2P76T {
  display: flex;
  width: 100%;
  margin: 10px 0 0;
}

.select-dashboard {
  box-shadow: 0px 1px 5px hsla(0, 0%, 50.2%, 0.5019607843137255);
  background: #fff;
  border: 2px solid #fff;
  font-size: 0.9em;
  width: 20%;
  margin: 0;
}

.form-control {
  padding: 0.475rem 0.75rem;
}

.secondColl {
  text-align: center;
  border-radius: 5px;
  color: #fff;
  font-weight: 400;
  padding: 10px 5px;
  word-break: normal;
  min-width: 150px;
}

.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative;
  box-shadow: 0px 1px 5px hsla(0, 0%, 50.2%, 0.5019607843137255);
  background: #fff;
  border: 2px solid #fff;
  padding: 2px 10px;
  border-radius: 5px;
}

.facebook {
  margin: 0 auto 10px !important;
}

.google,
.facebook {
  max-width: 260px;
}

.google {
  box-shadow: 0 0px 5px #dbdbdb;
}

.input-group > .form-control {
  background: #fff;
}

.redes a {
  margin: 1px !important;
  font-size: 0.9em;
}

.passForget a {
  font-size: 0.8em;
}

.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon {
  height: auto !important;
  width: 100% !important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 0 !important;
}

.css-1pahdxg-control {
  height: 50px;
}

.css-yk16xz-control {
  height: 50px;
  border-bottom: 1px solid grey;
  box-shadow: 0 1px 5px hsla(0, 0%, 50.2%, 0.501961);
}

.css-1hwfws3 {
  height: 50px;
}

.tagCont p {
  color: #fff !important;
}

.titleOneStep {
  font-size: 24px;
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 90%;
}

div#new-pauta-modal {
  padding: 0 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px #343a40;
}

.dataPick {
  height: 51px !important;
}

.contDate,
.dataPick {
  display: flex !important;
  align-items: flex-end !important;
  justify-content: flex-start !important;
}

.DateRangePicker_picker {
  z-index: 9 !important;
  background-color: #fff !important;
  position: absolute !important;
  left: 0% !important;
}

.DayPicker_transitionContainer {
  margin-bottom: 10px;
}

.bodyOneStep.w-100::-webkit-scrollbar {
  width: 10px;
  height: 20px;
}

.bodyOneStep.w-100::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #ff642e, #ff642e);
  border-radius: 20px;
  box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25),
    inset -2px -2px 2px rgba(0, 0, 0, 0.25);
}

.bodyOneStep.w-100::-webkit-scrollbar-track {
  border-radius: 20px;
  background: linear-gradient(90deg, #ccc, #c2c2c2 1px, #ccc 0, #b1b1b1);
}

.bodyOneStep::-webkit-scrollbar {
  width: 6px;
  height: 30px;
}

.bodyOneStep::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #ff642e, #ff642e);
  border-radius: 20px;
  box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25),
    inset -2px -2px 2px rgba(0, 0, 0, 0.25);
}

.bodyOneStep::-webkit-scrollbar-track {
  border-radius: 20px;
  background: linear-gradient(90deg, #ccc, #c2c2c2 1px, #ccc 0, #b1b1b1);
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  height: 50px;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] input {
  height: 50px;
  border-radius: 5px;
}

.MuiOutlinedInput-input {
  padding: 0 8px !important;
}

._1vTf4 {
  color: darkgrey;
}

.form-check-input {
  width: auto !important;
  height: auto !important;
  opacity: 1 !important;
}

.MuiAutocomplete-popper {
  z-index: 9999999 !important;
}

.settings-nav {
  padding: 0 0 0 0 !important;
  transition: 0.5s;
  left: -12px;
}

.settings-nav .titleOneStep {
  padding: 20px 0 0 85px;
  font-size: 16px;
}

.settings-nav-item a {
  color: #fff;
  width: 100%;
  display: flex;
  flex-flow: row-reverse;
  transition: all 0.5s ease;
  justify-content: left;
}

.settings-nav-item {
  margin: 0px 0;
  position: relative;
  padding: 10px 0 10px 85px;
  transition: all 0.5s ease;
  background-color: #d2d0d0;
}

.settings-nav-item:hover {
  background: #f9875f;
  color: #fff;
}

.settings-nav-item:hover a {
  color: #fff;
}

.settings-nav-itemClose {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 25px;
}

.settings-nav-itemClose a {
  color: #000;
}

.settings-nav-itemClose:hover {
  background: #f9875f;
}

.settings-nav-itemClose:hover a {
  color: #fff;
}

.titleOneStepClose {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-size: 25px;
}

.titleOneStep i {
  margin: 0 10px 0 0;
}

.settings-nav-item i {
  float: right;
  margin: 6px 15px 0 0;
  font-size: 0.8em;
}

.img-content-creativy {
  display: flex;
}

.imagenCreati {
  height: 50px;
  position: relative;
  margin-left: 15px;
}

.imagenCreati i {
  position: absolute;
  right: 0;
  color: white;
  cursor: pointer;
  background: red;
  width: 16px;
  height: 16px;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 0 2px black;
}

.OneColBody,
.twoColBody {
  padding: 0 5px;
}

.modal-newCamp {
  position: fixed;
}

.edit-fc {
  background: #ff3d57;
  color: #fff;
  padding: 10px;
  position: absolute;
  cursor: pointer;
  left: -45px;
  top: auto;
}
.firstColl {
  position: relative;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
  align-content: center;
}
._37QqY {
  height: 60px;
  display: flex;
  align-items: center;
  width: 70px;
  text-align: center;
  justify-content: center;
}

.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  box-shadow: 3px 3px 5px hsla(0, 0%, 50.2%, 0.5019607843137255);
}

.component h1.title {
  font-size: 2.3rem;
}

.space-btns.padd .facebook {
  margin: 0 !important;
  padding: 10px 10px;
}

.space-btns.padd .google {
  margin: 0 !important;
}

.form-dashboard .container.m-2.p-2 {
  max-width: 100% !important;
  background: #eff0f2;
  box-shadow: 5px 5px 15px #0000001f;
  border: 2px solid #ffffff;
  border-radius: 10px;
}

.form-dashboard .d-flex.w-100 ._3fTZM {
  padding: 0;
}

._3fTZM.rdt_Utils_Container.container {
  padding-right: 0;
}

.titleOneStep i,
.titleOneStepClose i {
  cursor: pointer;
}

.navbar .navbar-brand img {
  object-fit: contain;
}

._3LnVt ._2O0q6 {
  padding: 10px 5px 10px;
}

.rounded.form-control {
  margin: 10px 0 0;
}

._3LnVt,
._16c_4 > div {
  padding: 0 5px;
}

#new-audience-modal {
  max-width: 90%;
}

.account-selector__control.css-yk16xz-control {
  cursor: pointer;
}

._DSojl a:hover {
  color: #02025b !important;
}

.formInput {
  box-shadow: 1px 2px 5px hsla(0, 0%, 50.2%, 0.5019607843137255);
  margin: 5px 0 10px;
  outline: 0;
}

#viewSelect {
  cursor: pointer;
}

.people {
  width: 90%;
}

.navbar {
  border-radius: 0 0 15px 15px;
}

.swal2-popup {
  border-radius: 10px;
}

.oneColBody input,
.oneColBody select,
.twoColBody input,
.twoColBody select {
  margin: 0;
  min-height: 50px;
}

#new-category-modal,
#new-audience-modal,
div#new-account-modal {
  max-width: 80%;
  border-radius: 10px;
}

.form-group {
  margin-bottom: 1rem;
  padding: 0 5px;
}

.navbar-collapse {
  background: #fff;
  padding: 0 10px 0;
}

._1nB_u {
  width: 90%;
  margin: 0 auto;
}

._1nB_u a {
  margin: 0 10px 0 0;
}

.navbar .container {
  max-width: 1300px !important;
}

.form-dashboard .container.m-2.p-2 {
  margin: 10px 0 !important;
}

.iAwzhW {
  padding: 0 0;
}

.content-menu {
  flex-flow: row wrap;
}

#new-audience-modal,
#new-category-modal,
div#new-account-modal {
  max-width: 1080px;
}

.tagsContent {
  height: 39px;
}

.dXaTQl {
  padding: 0 0 0 15px;
}

@media (max-width: 1025px) {
  #new-audience-modal,
  #new-category-modal,
  div#new-account-modal {
    max-width: 80%;
  }

  .form-margin {
    width: 80%;
    justify-content: space-between;
  }

  .space-btns.padd .facebook {
    font-size: 0.7em;
  }

  .space-btns.padd .google {
    font-size: 0.7em;
    padding: 5px 10px;
  }
}

@media (max-width: 991px) {
  .pl-5,
  .px-5 {
    padding-left: 1rem !important;
  }

  .pr-5,
  .px-5 {
    padding-right: 1rem !important;
  }

  .navbar .container {
    padding: 0;
  }

  .css-1uccc91-singleValue {
    font-size: 0.8em;
  }

  .navbar .account-selector-container {
    min-width: 130px;
  }

  .bodyOneStep.w-100::-webkit-scrollbar {
    width: 6px;
    height: 30px;
  }

  div#new-pauta-modal {
    display: flex;
    flex-flow: column;
    padding: 10px;
    width: 90% !important;
  }

  .contDate,
  .dataPick {
    display: flex !important;
    align-items: flex-end !important;
    justify-content: space-between !important;
    margin: 0 0 0px;
  }

  .DateInput {
    margin: 0;
    padding: 0;
    position: relative;
    display: inline-block;
    width: 110px;
    vertical-align: middle;
  }

  .oneColBody input,
  .oneColBody select,
  .twoColBody input,
  .twoColBody select {
    min-height: 50px;
    background: #fff;
  }

  .oneColBody {
    margin: 10px 0 0;
  }

  .footOneStep {
    flex-flow: column;
    padding: 5px 0 0;
    margin: 0 0 10px;
    width: 89%;
  }

  .footOneStep a {
    width: 100%;
    text-align: center;
    margin: 10px 0 0px;
  }

  .footOneStep a:first-child {
    width: 100%;
    text-align: center;
    margin: 0;
  }

  .bodyOneStep {
    margin: 0 0 10px;
    width: 96.666%;
  }

  .Section__container___3YYTG {
    overflow-x: hidden !important;
  }

  .Section__container___3YYTG .form .form-margin {
    flex-flow: column;
    width: 100% !important;
  }

  .Section__container___3YYTG .form .form-margin .left-form {
    width: 100%;
    margin: 70px auto 0;
  }

  .Section__container___3YYTG .form .form-margin .right-form {
    width: 90%;
    margin: 20px 0;
  }

  .space-btns.padd .google {
    margin: 10px 0;
  }
}

@media (min-width: 768px) {
  .fancybox-content {
    padding: 24px;
  }
  .navbar-expand-md .navbar-collapse {
    margin: 8px 0 0 0;
  }

  .navbar-expand-md .navbar-nav {
    margin: 14px 0 0;
  }

  .mx-auto.nav-options.navbar-nav li a {
    border-bottom: 5px solid #fff;
    transition: all 0.5s ease;
  }

  .mx-auto.nav-options.navbar-nav li.active a,
  .mx-auto.nav-options.navbar-nav li a:hover {
    border-bottom: 5px solid #333;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    font-size: 0.5em;
  }

  .settings-nav .titleOneStep {
    padding: 20px 0 0 20px;
    font-size: 16px;
  }

  .settings-nav-item {
    padding: 10px 0 10px 30px;
  }
}

@media (max-width: 600px) {
  #new-pauta-modal .bodyOneStep .Section__container___3YYTG .form .form-margin {
    flex-flow: column;
    width: 100% !important;
    margin: 280px 0;
    padding: 0 !important;
  }

  .content-menu {
    flex-flow: column;
  }

  .select-dashboard {
    width: 100% !important;
    margin: 0 0 20px;
  }

  ._3fTZM {
    padding: 0 !important;
  }

  .bMeATW {
    display: none !important;
  }

  .dropdown,
  .dropleft,
  .dropright,
  .dropup {
    padding: 2px 5px;
  }

  .titleOneStep {
    height: auto;
    padding: 5px 0;
  }

  .bodyOneStep {
    display: flex !important;
    flex-flow: column;
  }
}

div#new-category-modal .formOneStep .bodyOneStep {
  overflow: visible;
}

div#new-category-modal {
  padding: 100px 120px;
}

/*._button_add_1aspn_34{
  margin-right: 60px;
}*/

.HelpBtn {
  border: none;
  cursor: pointer;
  width: 30px;
  padding: 8px;
  height: 33.28px;
  position: relative;
  bottom: 12px;
  background-color: transparent;
}
.HelpBtn img {
  color: rgb(0, 0, 0);
  width: 20px;
  margin-bottom: 6px;
}
.contenedorDashboard {
  position: relative;
}

/* css de modal*/
.blur-div {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(8px);
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-modal {
  background: #eff0f2;
  width: 55%;
  height: 60%;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 7px 14px #00000040;
}

.closeModal-content {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: #cccccc;
  position: relative;
  top: 20px;
  right: 20px;
}

.closeModal-content i {
  font-size: 25px;
  margin: 10px 10px 0;
  cursor: pointer;
}

.titleModal {
  font-size: 23px;
  margin: 0 3%;
  background-color: #eff0f2;
}
/********************************/

.moderation-modal {
  background: #eff0f2;
  width: 70%;
  height: 70%;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 7px 14px #00000040;
}

/********************************/
.body-modal {
  height: calc(100% - 136px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  background-color: #eff0f2;
  padding: 20px 6% 0;
}

.part-BodyModal {
  width: 43%;
}

.part-BodyModal p {
  margin: 0;
  font-weight: bold;
}

.buttons-Modal-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.footer-Modal {
  display: flex;
  background: #eff0f2;
  padding: 20px 6%;
}

.buttons-Modal-content button {
  border: none;
  padding: 5px 5%;
  box-shadow: 5px 5px 15px #00000029;
  border-radius: 5px;
  color: white;
  font-weight: bold;
}

.buttons-Modal-content button:nth-child(1) {
  background: #7e7e80;
  font-size: 1rem;
}

.buttons-Modal-content button:nth-child(2) {
  background: #ff9015;
}
@media (max-width: 1500px) {
  .content-modal {
    height: 90%;
  }
}

/***fin deseño modal**/
