.configuration-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 50px;
  
    @media screen and (min-width: 768px) {
        .timepickers-container {
            display: flex;
            justify-content: center;
            gap: 10px;
            flex-direction: row;
        }
        .btn-container {
            display: flex;
            justify-content: center;
            gap: 10px;
            flex-direction: row;
        }
    }
  
    @media screen and (max-width: 767px) {
        .timepickers-container {
            display: flex;
            justify-content: center;
            gap: 10px;
            flex-direction: column;
        }
        .btn-container {
            display: flex;
            justify-content: center;
            gap: 10px;
            flex-direction: row;
        }
    }
  }
  .btn_import {
    border-radius: 5px;
    cursor: pointer;    
    transition: 0.3s;
    font-size: 20px;
    color: gray;
  }

  @media screen and (max-width: 1125px) {
    .table_data {
        flex-direction: column;
    }
}

  .table_data {
    display: flex;
    align-items: flex-start;
    gap: 50px;
  }

  .free_days {
    align-items: center;
    gap: 10px;
    margin: 20px 10px;
  }

  @media screen and (max-width: 480px) {
    .table_horarios .ant-table-wrapper   {
        overflow-x: hidden;
    }
    .free_days {
        overflow-x: hidden;
      }
      .sub_cointainer_free_days {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .text_title {
        width: auto;
    }
}

@media screen and (max-width: 456px) {
    .btn-container {
        display: flex;
        gap: 1rem;
        flex-direction: column;
    }
}

.container_free_days {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    max-width: 100%;
}

.sub_cointainer_free_days {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.range_picker_container {
    width: 100%;
}

.select_picker_container{
    width: 100%;
}

.share_container_button {
    display: flex;
    align-items: flex-start;
}
