.desktop {
  .titleOneStep {
    padding-top: 0 !important ;
    height: 60px;
  }
}

.contenedorConfig {
  margin: 0;
}

.accountsPage .responsive {
  padding-top: 20px !important;
  .settings-nav-link {
    width: 25px;
    height: 25px;
    margin-bottom: 18px;
    i {
      width: 100%;
      height: 100%;
      &::before {
        display: block;
        object-fit: contain;
        width: 25px;
        height: 25px;
        font-size: 24px;
        text-align: center;
      }
    }
  }
}
