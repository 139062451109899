.footOneStepModal2{
  position: relative;
  bottom: -3rem;
  padding: 0 6%;
  .firstBtn {
    width: 250px !important;
    text-align: center;
  }
  .secondBtn {
    width: 250px !important;
    text-align: center;
  }
}