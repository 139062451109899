.accountsPage {
  display: grid;
  grid-template-columns: auto 80%;
  justify-content: center;
  padding: 2rem 5% 0;
  width: 100%;
  margin: 0;
  .settings-nav {
    width: 280px;
    height: calc(100vh - 130px) !important;
  }
  .con {
    width: 120px;
  }
  .contenedorConfig {
    margin: 0;
    width: 100%;
    height: calc(100vh - 130px) !important;
  }
  .responsive {
    display: none !important;
  }
}

@media (max-width: 1100px) {
  .accountsPage {
    .desktop {
      display: none;
    }
    .responsive {
      display: block !important;
      width: 100px;
    }
  }
}

@media (max-width: 768px) {
  .accountsPage {
    padding: 2rem 0 0;
    .responsive {
      width: 60px;
    }
  }
}
