a.disabled {
  opacity: .5 !important;
  pointer-events: none !important;
}
.left-form{
  width: 50%;
  /* overflow: hidden; */
}

.Content-componets{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
  width: 100%;
}

/* .component{
  width: 12.5%;
  position: relative;
} */

.title{
  color: #292f4c;
}

.input{
  width: 100%;
  border: none;
  margin-top: 20px;
  border-bottom: 1px solid grey;
}

.disa:first-child {
  color: grey !important;
}

.dataPick{
  position: relative;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid grey;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.contDate{
  display: flex;
  align-items: center;
}

.calen{
  font-size: 20px;
  padding: 5px;
}

.rigth-btn{
  text-align: right;
}

.space-btns{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.space{
  margin: 20px 0 10px;
}

.padd{
  padding: 10px;
}

.btn-camp{
  background: orangered;
  color: white;
  padding: 10px 30px;
  text-decoration: none;
  margin: 20px 5px 10px;
  display: inline-block;
  border-radius: 5px;
  box-shadow: 1px 1px 5px #80808096;
  width: 45%;
  text-align: center;
  font-weight: bold;
}

.form-tar{
  margin-top: 20px;
}

.dateCvv{
  width: 98%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#cvv::after{
  content: "?";
  color: red;
  font-weight: bold;
}

.mitad{
  width: 45%;
}

.all{
  width: 98% !important;
}

.margin{
  margin: 5px 0 20px 0 !important;
}

.noBorder{
  border: none;
  width: 95%;
}

.interogative{
  display: inline;
  color: red;
  font-weight: bold;
}

.btn-end{
  background: orangered;
  color: white;
  padding: 10px 30px;
  text-decoration: none;
  margin: 20px 5px 0 5px;
  display: inline-block;
  border-radius: 5px;
  box-shadow: 1px 1px 5px #80808096;
  text-align: center;
  font-weight: bold;
}

.gray{
  background: white;
  color: #b7b7b7;
  border: 1px solid #80808096;
}

.btn-camp:hover{
  color: #fff;
  text-decoration: none;
}

.gray:hover{
  color: #b7b7b7;
  text-decoration: none;
}

.DateRangePickerInput__withBorder{
  border: none;
}

.CalendarDay__selected_span {
  background: #ff642e; 
  color: white; 
  border: 1px solid #ff642e; 
}

.CalendarDay__selected {
  background: #ff4e0e;
  color: white;
  border: 1px solid red; 
}

.CalendarDay__selected_span:hover {
  background: orange; 
  color: white;
  border: 1px solid red;
}


.CalendarDay__selected:hover {
  background: orange;
  color: white;
}

.tagsContent{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
}

.tagCont{
  background: #ff3d57;
  margin: 5px;
  padding: 0 10px 0 15px;
  border-radius: 25px;
  color: white;
  display: flex;
  align-items: center;
}

.tagCont p{
  margin: 0;
  padding-right: 20px;
}

.require::placeholder{
  color: #ff3d57;
}

.tagCont:nth-child(2){
  background: #ff642e;
}

.tagCont:nth-child(3){
  background: #fdab3d;
}

.tagCont:nth-child(4){
  background: #00ca72;
}

.tagCont:nth-child(5){
  background: #009aff;
}

.tagCont:nth-child(6){
  background: #794fcf;
}

.tagCont:nth-child(7){
  background: #cacacc;
}

.tagCont:nth-child(8){
  background: #292f4c;
}

.all {
  width: 100% !important;
}

@media (max-width: 1600px){
  
}

.unstyled {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: row;
}
.list{ 
  margin-right: 30px;
}

.centered {
  margin: auto;
}

.nameCheck {
  padding: 0;
}
.checks{ 
  margin-top: 5px;
}
.nameCheck::before {
  content: '';
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: white;
}
.nameCheck::after{
  content: '';
  position: absolute;
  left: 5px;
  top: 9px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 
    2px 0 0 white,
    4px 0 0 white,
    4px -2px 0 white,
    4px -4px 0 white,
    4px -6px 0 white,
    4px -8px 0 white;
  transform: rotate(45deg);
}

.styled-checkbox {
  position: absolute;
}
  
/* li {
  margin: 20px 0;
} */

.formPhone{
  float: right;
  width: 79%;
}
.formatPhone{ 
  margin-top: 3px !important;
  height: 50px !important;
}