.configuration-container {
    max-width: 100%;
    margin: 2rem 2rem;
  }

  
  .btn-container {
    margin-top: 20px;
    text-align: center;
  }
  
  .btn {
    margin-right: 10px;
  }

  .timepickers-container{
    width: 100%;
  }

  .text_priority_setting {
    text-align: center;
  }
  .input-min-width {
    display: flex;
    flex-direction: column;
    width: auto;
    height: 40px;
  }

  @media screen and (max-width: 440px){
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
      padding: 2.3px 2.3px;
      overflow-x: hidden;
    }
  }

  @media screen and (max-width: 440px){
    .text_priority_setting {
      text-align: center;
    }
  }
  

  @media screen and (max-width: 300px){
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
      padding: 0px 0px;
      overflow-x: hidden;
      font-size: 10px;
    }
    .input-min-width {
      width: 30px;
    }
    .ant-input-number-input{
      width: 30px;
    }
  }

  @media screen and (max-width: 260px){
    .text_priority_setting {
      text-align: center;
    }
  }
  
  